import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  urlInformacoes,
  urlAgendamentosAlterarStatus,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonLabel,
  IonPage,
  IonContent,
  IonCard,
  IonSelect,
  IonSelectOption,
  IonItem,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";
import { toastNotificacao } from "../../../utils/functions";
import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function AgendamentoEmpresas() {
  const [itens, setItens] = useState([]);
  const [status, setStatus] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const statusArray = [
    {
      id: 1,
      nome: "Pendente",
    },
    {
      id: 2,
      nome: "Em andamento",
    },
    {
      id: 3,
      nome: "Não confirmado",
    },
    {
      id: 4,
      nome: "Finalizado",
    },
    {
      id: 5,
      nome: "Cancelado",
    },
    {
      id: 6,
      nome: "Reembolsado",
    },
  ];

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data[0];
    } catch (error) {
      console.log(error);
    }
  };
  const alterarStatus = async () => {
    try {
      const response = await axios.post(urlAgendamentosAlterarStatus, {
        token: tokenUsuario,
        status: status,
        id: idPage,
      });
      if (response) {
        const irParaAgendmamentos = () => {
          navigate({ pathname: "/empresas/agendamentos" });
        };
        toastNotificacao(
          "success",
          "alert-outline",
          "Status alterado com sucesso!",
          2000,
          "Ok",
          irParaAgendmamentos()
        );
      }
    } catch (error) {
      toastNotificacao(
        "danger",
        "alert-outline",
        "Não foi possível alterar o status!",
        2000,
        "Ok"
      );
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = "agendamentos";

    const url = urlInformacoes(tipo, tela, `id=${idPage}`);
    const lista = await consultaLista(url);
    setItens(lista);
    setStatus(lista.status);
  };

  useEffect(() => {
    consultarItens();
  }, []);

  function calcularDiferencaEmMinutos(horario1, horario2) {
    if (horario1 && horario2) {
      // Divida os horários em horas, minutos e segundos
      const [hora1, minuto1, segundo1] = horario1.split(":").map(Number);
      const [hora2, minuto2, segundo2] = horario2.split(":").map(Number);

      // Calcule a diferença em minutos
      const minutos1 = hora1 * 60 + minuto1 + segundo1 / 60;
      const minutos2 = hora2 * 60 + minuto2 + segundo2 / 60;

      // Calcule a diferença entre os minutos
      const diferencaEmMinutos = Math.abs(minutos2 - minutos1);

      return diferencaEmMinutos;
    }
  }

  return (
    <IonPage>
      <HeaderApp />
      <IonContent className="contentAgendamento">
        <br />
        <div className="ion-text-center">
          <h1 style={{ fontSize: 40 }}>
            {calcularDiferencaEmMinutos(
              itens.horario_inicial,
              itens.horario_final
            )}
            <b style={{ fontSize: 15 }}>&nbsp;min</b>
          </h1>
        </div>

        <IonCard>
          <IonLabel>
            <div className="ion-padding">
              <h2>
                <i className="ri-calendar-line"></i>&nbsp;
                {moment(itens.data_agendamento).format("D")} de{" "}
                {moment(itens.data_agendamento).format("MMMM")} de{" "}
                {moment(itens.data_agendamento).format("YYYY")}
              </h2>
              <h5 style={{ marginTop: "7px", marginBottom: "5px" }}>
                <i className="ri-time-line"></i>&nbsp;
                {moment("2023-01-01 " + itens.horario_inicial).format(
                  "HH:mm"
                )}{" "}
                / {moment("2023-01-01 " + itens.horario_final).format("HH:mm")}
              </h5>
              <h5>
                <i className="ri-store-2-line"></i>&nbsp; Serviço:{" "}
                {itens.servico}
              </h5>
              <h5>
                <i className="ri-user-3-line"></i> &nbsp; Cliente:{" "}
                {itens.cliente_nome}
              </h5>
              {itens.status && (
                <h5 style={{ marginTop: "7px", marginBottom: "5px" }}>
                  <StatusAgendamento status={itens.status} />
                  <p style={{ marginTop: "7px", marginBottom: "5px" }}>
                    <i className="ri-information-line"></i> &nbsp; {itens.obs}
                  </p>
                </h5>
              )}
            </div>
          </IonLabel>
        </IonCard>

        <IonItem>
          <IonSelect
            mode="ios"
            aria-label="Status"
            placeholder="Clique aqui"
            onIonChange={(e) => setStatus(e.detail.value)}
            value={status}
          >
            {statusArray.map((item, index) => (
              <IonSelectOption key={index} value={item.id}>
                {item.nome}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <br />
        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="full"
            onClick={() => alterarStatus()}
          >
            Atualizar status
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
