import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { urlAgendamentosPorDia, tokenUsuario } from "../../../utils/constants";
import { IonCard, IonLabel } from "@ionic/react";
import Chart from "chart.js/auto";

export default function AgendamentosSemana({
  totalClientes,
  totalAgendamentos,
}) {
 
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);
 
  const listaEmpresas = async () => {
    try {
      const response = await axios.post(urlAgendamentosPorDia, {
        token: tokenUsuario,
      });
      totalClientes(response.data.total_clientes.total);
      totalAgendamentos(response.data.total_agendamentos.total);
      if (response.data.agendamentos_semana) {
        const cores = ["#13aad7"];
        const labels = response.data.agendamentos_semana.map(
          (item) => item.data
        );
        const data = response.data.agendamentos_semana.map(
          (item) => item.total
        );
        const backgroundColors = cores;

        updateChart(labels, data, backgroundColors);
      } else {
        throw new Error("No data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateChart = (labels, data, backgroundColors) => {
    if (chart) {
      chart.destroy();
    }

    const ctx = chartRef.current.getContext("2d");

    const newChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Agendamentos",
            data: data,
            backgroundColor: backgroundColors,
            barThickness: 25,
            borderRadius: 20,
          },
        ],
      },
      options: {
        aspectRatio: 1,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              display: true,
              color: "#FFFFFF",
              drawTicks: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
              color: "#FFFFFF",
              drawTicks: false,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
    setChart(newChart);
  };

  useEffect(() => {
    listaEmpresas();
    setTimeout(() => {}, 1000);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  return (
    <div>
      <IonCard>
        <IonLabel>
        <h2
          className="ion-text-center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Agendamentos da Semana
        </h2>
        </IonLabel>
        <canvas
          id="myChart"
          ref={chartRef}
          style={{ width: "95%", height: "auto" }}
        ></canvas>
      </IonCard>
    </div>
  );
}
