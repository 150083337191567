import React, { useEffect, useRef, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  urlListarCalendarioAgenda,
  tokenUsuario,
} from "../../../utils/constants";
import {
  IonPage,
  IonContent,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
} from "@ionic/react";

import Calendar from "@event-calendar/core";
import TimeGrid from "@event-calendar/time-grid";
import List from "@event-calendar/list";
import DayGrid from "@event-calendar/day-grid";
import MenuSide from "../../../components/empresas/MenuSide";
import HeaderApp from "../../../components/global/HeaderItem";

import { alertNotificationMultiple } from "../../../utils/functions";

import "@event-calendar/core/index.css";

export default function CalendarioAgenda() {
  const calendarRef = useRef();
  const navigate = useNavigate();

  const [view, setView] = useState("dayGridMonth");
  const [calendar, setCalendar] = useState(null);

  const listar = async () => {
    try {
      const response = await axios.post(urlListarCalendarioAgenda, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      const lista = await listar();
      const eventosMapeados = lista.map((evento) => {
        const startDateTime = `${evento.date} ${evento.start}`;
        const endDateTime = `${evento.date} ${evento.end}`;
        const titleService = `${evento.title} : ${evento.service_name}`;

        return {
          ...evento,
          start: startDateTime,
          end: endDateTime,
          title: titleService,
        };
      });
      if (calendar) {
        calendar.destroy();
      }
      let ec = new Calendar({
        target: calendarRef.current,
        props: {
          plugins: [DayGrid, List, TimeGrid],
          options: {
            allDaySlot: false,
            initialView: view,
            view: view,
            events: eventosMapeados,
            buttonText: {
              today: "Hoje",
            },
            headerToolbar: {
              start: "title",
              center: "",
              end: "today prev,next",
            },
            eventClick: function (info) {
              const funcao = () => {
                return;
              };
              const funcao2 = () => {
                navigate({
                  pathname: "/empresas/agendamento",
                  search: createSearchParams({
                    id: info.event.id,
                  }).toString(),
                });
              };
              let titulo = info.event.title;
              let descricao = `
                ${moment(info.event.start).format("DD/MM/YYYY")}: 
                ${moment(info.event.start).format("HH:mm")} -
                ${moment(info.event.end).format("HH:mm")}`;
              let textoBotao = "Fechar";
              let textoBotao2 = "Ir para o agendamento";
              alertNotificationMultiple(
                titulo,
                descricao,
                textoBotao,
                funcao,
                textoBotao2,
                funcao2
              );
              console.log("Evento clicado:", info.event);
            },
          },
        },
      });
      setCalendar(ec);
    };
    run();
  }, [view]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel>Tipo de Calendário</IonLabel>
          <IonSelect
            mode="ios"
            aria-label="Tipo de Calendário"
            placeholder="Clique aqui"
            onIonChange={(e) => setView(e.detail.value)}
            value={view}
          >
            <IonSelectOption value={"listDay"}>Lista diária</IonSelectOption>
            <IonSelectOption value={"listWeek"}>Lista semanal</IonSelectOption>
            <IonSelectOption value={"dayGridMonth"}>Mês</IonSelectOption>
            <IonSelectOption value={"timeGridDay"}>Diário</IonSelectOption>
            <IonSelectOption value={"timeGridWeek"}>Semanal</IonSelectOption>
          </IonSelect>
        </IonItem>
        <div style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}>
          <div ref={calendarRef}></div>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
