import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlListar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
  IonSpinner,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";
import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function AgendamentosUsuario() {
  const navigate = useNavigate();

  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [pagina, setPagina] = useState(1);
  const [loading, setLoading] = useState(true);

  const [itens, setItens] = useState([]);
  const location = useLocation();

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url + `?pagina=${pagina}`, {
        token: tokenUsuario,
        data_inicial: dataInicial,
        data_final: dataFinal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = partesDoCaminho[2];

    const url = urlListar(tipo, tela);
    const lista = await consultaLista(url);
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, [pagina, dataFinal]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin-horizontal">
          <div className="row">
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataInicial(event.target.value);
                  }}
                  value={dataInicial}
                />
              </IonItem>
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataFinal(event.target.value);
                  }}
                  value={dataFinal}
                />
              </IonItem>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="ion-text-center" style={{ marginTop: '10vh' }}>
             <IonSpinner name="crescent" />
          </div>
        ) : (
          <div>
            {itens.length > 0 ? (
              <div>
                <div>
                  <IonList>
                    {itens.map((row, index) => (
                      <IonItem
                        onClick={() => {
                          navigate({
                            pathname: "/usuarios/agendamento",
                            search: createSearchParams({
                              id: row.id,
                            }).toString(),
                          });
                        }}
                        key={index}
                      >
                        <IonLabel>
                          <h5
                            style={{
                              textTransform: "capitalize",
                              fontWeight: 700,
                            }}
                          >
                            {moment(row.data_agendamento).format("D")} de{" "}
                            {moment(row.data_agendamento).format("MMMM")} de{" "}
                            {moment(row.data_agendamento).format("YYYY")} :{" "}
                            {moment("2023-01-01 " + row.horario_inicial).format(
                              "HH:mm"
                            )}{" "}
                            -{" "}
                            {moment("2023-01-01 " + row.horario_final).format(
                              "HH:mm"
                            )}
                          </h5>
                          <h5>Serviço: {row.servico}</h5>
                          <h5>Empresa: {row.empresa_nome}</h5>
                          <StatusAgendamento status={row.status} />
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    marginTop: "30vh",
                    marginBottom: "30vh",
                  }}
                >
                  Sem agendamentos!
                </div>
              </div>
            )}
            <div className="ion-margin-horizontal">
              <div className="row">
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
                      }}
                    >
                      Anterior
                    </IonButton>
                  </div>
                </div>
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => prevPagina + 1);
                      }}
                    >
                      Próximo
                    </IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
