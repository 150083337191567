import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const [codigo, setCodigo] = useState("");
  const [ddd, setDDD] = useState("");
  const [numero, setNumero] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "clientes"), {
        token: tokenUsuario,
        nome: nome,
        usuario: email,
        codigo: codigo,
        ddd: ddd,
        telefone: numero,
      });
      if (response) {
        navigate({ pathname: "/empresas/clientes" });
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Código do país: </IonLabel>
          <IonInput
            type="tel"
            className="codigo"
            placeholder="Código do país"
            onIonInput={(event) => {
              event.preventDefault();
              setCodigo(event.target.value);
            }}
            value={codigo}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">DDD: </IonLabel>
          <IonInput
            type="tel"
            className="ddd"
            placeholder="DDD"
            onIonInput={(event) => {
              event.preventDefault();
              setDDD(event.target.value);
            }}
            value={ddd}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Número: </IonLabel>
          <IonInput
            type="tel"
            className="numero"
            placeholder="Número"
            onIonInput={(event) => {
              event.preventDefault();
              setNumero(event.target.value);
            }}
            value={numero}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Nome e Sobrenome: </IonLabel>
          <IonInput
            type="text"
            className="nome"
            placeholder="Nome e Sobrenome"
            onIonInput={(event) => {
              event.preventDefault();
              setNome(event.target.value);
            }}
            value={nome}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">E-mail: </IonLabel>
          <IonInput
            type="email"
            className="usuario"
            placeholder="E-mail"
            onIonInput={(event) => {
              event.preventDefault();
              setEmail(event.target.value);
            }}
            value={email}
          />
        </IonItem>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
