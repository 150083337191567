import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [tempo, setTempo] = useState("");

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "servicos"), {
        token: tokenUsuario,
        nome: nome,
        valor: valor,
        tempo: tempo,
      });
      if (response) {
        navigate({ pathname: "/empresas/servicos" });
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Nome: </IonLabel>
          <IonInput
            type="text"
            placeholder="Nome"
            onIonInput={(event) => {
              event.preventDefault();
              setNome(event.target.value);
            }}
            value={nome}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Valor: </IonLabel>
          <IonInput
            type="tel"
            placeholder="Valor"
            onIonInput={(event) => {
              event.preventDefault();
              setValor(event.target.value);
            }}
            value={valor}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Tempo: </IonLabel>
          <IonInput
            type="tel"
            placeholder="Tempo"
            onIonInput={(event) => {
              event.preventDefault();
              setTempo(event.target.value);
            }}
            value={tempo}
          />
        </IonItem>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
