import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  urlContaEmpresas,
  urlEnviarCodigoPerfilEmpresas,
  urlAlterar,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonInput,
  IonToggle,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonToolbar,
  IonModal,
  IonHeader,
  IonTitle,
  IonButtons,
  IonSpinner,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";

import { toastNotificacao } from "../../../utils/functions";
import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function ConfiguracoesEmpresa() {

  const [pagamentoAntecipado, setPagamentoAntecipado] = useState("");
  const [pagamentoObrigatorio, setPagamentoObrigatorio] = useState("");
  const [mercadoPagoToken, setMercadoPagoToken] = useState("");
  const [percentualPagamento, setPercentualPagamento] = useState("");
  const [tempoLimitePagamentoAntecipado, setTempoLimitePagamentoAntecipado] =
    useState("");
  const [intervaloHorarios, setIntervalosHorarios] =
    useState("");
  const [loadingPerfil, setLoadingPerfil] = useState(true);

  const [modalCodigo, setModalCodigo] = useState(false);

  const consultaLista = async () => {
    try {
      const response = await axios.post(urlContaEmpresas, {
        token: tokenUsuario,
      });
      setLoadingPerfil(false);
      return response.data;
    } catch (error) {
      setLoadingPerfil(false);
    }
  };

  const consultaCodigoAtualizarPerfil = async () => {
    try {
      const response = await axios.post(urlEnviarCodigoPerfilEmpresas, {
        token: tokenUsuario,
      });
      if (response) {
        setModalCodigo(true);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const consultarItens = async () => {
    const lista = await consultaLista();
    setPagamentoAntecipado(lista.pagamento_antecipado);
    setPagamentoObrigatorio(lista.pagamento_obrigatorio);
    setMercadoPagoToken(lista.acess_token);
    setPercentualPagamento(lista.porcentagem_pagamento_antecipado);
    setTempoLimitePagamentoAntecipado(lista.horas_limite_pagamento);
    setIntervalosHorarios(lista.intervalo_horarios)
  };

  useEffect(() => {
    consultarItens();
  }, []);

  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const [codigo5, setCodigo5] = useState("");
  const [codigo6, setCodigo6] = useState("");

  const [loading, setLoading] = useState(false);

  const codigoRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleCodigoChange = (e, index) => {
    let value = e.target.value;

    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    switch (index) {
      case 0:
        setCodigo1(value);
        break;
      case 1:
        setCodigo2(value);
        break;
      case 2:
        setCodigo3(value);
        break;
      case 3:
        setCodigo4(value);
        break;
      case 4:
        setCodigo5(value);
        break;
      case 5:
        setCodigo6(value);
        break;
      default:
        break;
    }

    if (value.length === 1 && index < 5) {
      codigoRefs[index + 1].current.focus();
    } else {
      if (value !== "") {
        verificarCodigo(value);
      }
    }
  };

  const verificarCodigo = async (codigoSeis) => {
    const codigoEnviar = `${codigo1}${codigo2}${codigo3}${codigo4}${codigo5}${codigoSeis}`;
    try {
      const url = urlAlterar("empresas", "configuracoes");
      const response = await axios.post(url, {
        valor: codigoEnviar,
        acess_token: mercadoPagoToken,
        pagamento_antecipado: pagamentoAntecipado,
        pagamento_obrigatorio: pagamentoObrigatorio,
        porcentagem_pagamento_antecipado: percentualPagamento,
        horas_limite_pagamento: tempoLimitePagamentoAntecipado,
        intervalo_horarios: intervaloHorarios,
        token: tokenUsuario,
      });
      if (response.data.status === 1) {
        setModalCodigo(false);
        setLoading(false);
        toastNotificacao(
          "success",
          "alert-outline",
          "Informações atualizadas com sucesso",
          10000,
          "Ok"
        );
      } else {
        setLoading(false);
        toastNotificacao(
          "danger",
          "alert-outline",
          "Código inválido ou expirado.",
          10000,
          "Ok"
        );
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent className="contentAgendamento">
        {loadingPerfil ? (
          <div className="ion-text-center" style={{ marginTop: "10vh" }}>
            <IonSpinner name="crescent" />
          </div>
        ) : (
          <div>
            {pagamentoAntecipado == 1 ? (
              <IonItem>
                <IonLabel>Pagamento antecipado</IonLabel>
                <IonToggle
                  checked="true"
                  mode="ios"
                  slot="end"
                  color="secondary"
                  onIonChange={() => setPagamentoAntecipado(0)}
                  value="1"
                ></IonToggle>
              </IonItem>
            ) : (
              <IonItem>
                <IonLabel>Pagamento antecipado</IonLabel>
                <IonToggle
                  mode="ios"
                  slot="end"
                  color="secondary"
                  onIonChange={() => setPagamentoAntecipado(1)}
                  value="0"
                ></IonToggle>
              </IonItem>
            )}

            {pagamentoObrigatorio == 1 ? (
              <IonItem>
                <IonLabel>Pagamento obrigatório</IonLabel>
                <IonToggle
                  checked="true"
                  mode="ios"
                  slot="end"
                  color="secondary"
                  onIonChange={() => setPagamentoObrigatorio(0)}
                  value="1"
                ></IonToggle>
              </IonItem>
            ) : (
              <IonItem>
                <IonLabel>Pagamento obrigatório</IonLabel>
                <IonToggle
                  mode="ios"
                  onIonChange={() => setPagamentoObrigatorio(1)}
                  slot="end"
                  color="secondary"
                  value="0"
                ></IonToggle>
              </IonItem>
            )}

            <IonItem>
              <IonLabel position="stacked">Mercado pago - Token</IonLabel>
              <IonInput
                placeholder="Mercado pago - Token"
                type="text"
                onIonChange={(e) => setMercadoPagoToken(e.detail.value)}
                value={mercadoPagoToken}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                Porcentagem pagamento antecipado %
              </IonLabel>
              <IonInput
                placeholder="Porcentagem pagamento antecipado %"
                type="tel"
                onIonChange={(e) => setPercentualPagamento(e.detail.value)}
                value={percentualPagamento}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                Tempo limite pag. Antecipado
              </IonLabel>
              <IonInput
                placeholder="Em minutos"
                type="tel"
                onIonChange={(e) =>
                  setTempoLimitePagamentoAntecipado(e.detail.value)
                }
                value={tempoLimitePagamentoAntecipado}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">
                Intervalo entre os horários do agendamento (Em minutos)
              </IonLabel>
              <IonInput
                placeholder="Em minutos"
                type="tel"
                onIonChange={(e) =>
                  setIntervalosHorarios(e.detail.value)
                }
                value={intervaloHorarios}
              />
            </IonItem>

            <div className="ion-margin">
              <IonButton
                color="secondary-light"
                expand="full"
                onClick={consultaCodigoAtualizarPerfil}
              >
                Atualizar perfil &nbsp;{" "}
                <i className="ri-refresh-line icone19px"></i>
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
      {modalCodigo ? (
        <IonModal isOpen={true} mode="ios" backdropDismiss="false">
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onclick={() => setModalCodigo(false)}>
                  Fechar
                </IonButton>
              </IonButtons>
              <IonTitle>Código de verificação</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <div className="ion-margin">
              <div className="row">
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                  <div className="ion-margin ion-text-center">
                    <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
                    <lord-icon
                      src="https://cdn.lordicon.com/flvisirw.json"
                      trigger="loop"
                      delay="2000"
                      style={{ width: 150, height: 150 }}
                    ></lord-icon>

                    <h1
                      className="titulo"
                      style={{
                        fontSize: 25,
                        fontWeight: 900,
                        marginTop: 0,
                      }}
                    >
                      Código de verificação
                    </h1>
                    <br />
                    <h6>
                      Informe o código de 6 números enviado no seu
                      Whatsapp/E-mail. (No caso do e-mail, verifique se está
                      indo para da caixa de SPAM.)
                    </h6>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {codigoRefs.map((ref, index) => (
                      <div
                        style={{ width: "100%", margin: 0, padding: 0 }}
                        key={index}
                      >
                        <input
                          className="inputCodigoVerificacao"
                          style={{
                            margin: 0,
                            width: 50,
                            border: "solid #333333 1px",
                            borderRadius: 11,
                          }}
                          value={
                            index === 0
                              ? codigo1
                              : index === 1
                              ? codigo2
                              : index === 2
                              ? codigo3
                              : index === 3
                              ? codigo4
                              : index === 4
                              ? codigo5
                              : codigo6
                          }
                          onChange={(e) => handleCodigoChange(e, index)}
                          type="tel"
                          ref={ref}
                        />
                      </div>
                    ))}
                  </div>
                  <br /> <br />
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        marginTop: "20%",
                      }}
                    >
                      
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="col-sm-4"></div>
              </div>
            </div>
          </IonContent>
        </IonModal>
      ) : null}

      <MenuSide />
    </IonPage>
  );
}
