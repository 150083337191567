import React, { useState, useEffect } from "react";
import axios from "axios";
import { urlResumoMensalConta, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import { IonProgressBar, IonContent, IonPage } from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";
import CardResumo from "../dashboard/CardResumo";

const consultaConta = async () => {
  try {
    const response = await axios.post(urlResumoMensalConta, {
      token: tokenUsuario,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const percentualAgendamentosContaGratis = (valor) => {
  if (valor) {
    return Number(valor) / 10;
  }
};

export default function ConsumoUsuario() {
  const [infoUsuario, setInfoUsuario] = useState([]);

  useEffect(() => {
    const run = async () => {
      const infoUsuario = await consultaConta();
      setInfoUsuario(infoUsuario);
    };
    run();
  }, []);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin">
          <div className="row">
            <div className="col-sm-4">
              <div className="card">
                <h5 style={{ marginLeft: 10, fontWeight: 500 }}>
                  Agendamentos do mês atual
                </h5>
                <div className="col-10">
                  <IonProgressBar
                    style={{
                      marginLeft: 10,
                      width: "100%",
                      height: 10,
                      borderRadius: 11,
                    }}
                    value={percentualAgendamentosContaGratis(
                      infoUsuario.total_agendamentos
                    )}
                    color={
                      percentualAgendamentosContaGratis(
                        infoUsuario.total_agendamentos
                      ) >= 1
                        ? "danger"
                        : "secondary-light"
                    }
                  ></IonProgressBar>
                </div>
                <div className="ion-margin-horizontal">
                  {percentualAgendamentosContaGratis(
                    infoUsuario.total_agendamentos
                  ) >= 1 ? (
                    <h5 style={{ fontWeight: 400, fontSize: 12 }}>
                      Você atingiu o limite de uso do plano Gratuito, assine o
                      plano profissional para continuar utilizando os recursos
                      no mês atual.
                    </h5>
                  ) : (
                    <h5 style={{ fontWeight: 400, fontSize: 12 }}>
                      Você tem um total de {infoUsuario.total_agendamentos}{" "}
                      agendamentos no mês atual, sendo que o limite é de 10 para
                      o plano Gratuito.
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <CardResumo
                texto={"Clientes"}
                icone={"ri-user-line"}
                valor={infoUsuario.total_clientes}
              />
            </div>
            <div className="col-6">
              <CardResumo
                texto={"Servicos"}
                icone={"ri-store-line"}
                valor={infoUsuario.total_servicos}
              />
            </div>
            <div className="col-6">
              <CardResumo
                texto={"Dias não atendidos"}
                icone={"ri-calendar-close-fill"}
                valor={infoUsuario.total_dias_nao_atendidos}
              />
            </div>
          </div>
        </div>
        <MenuSide />
      </IonContent>
    </IonPage>
  );
}
