import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import {
  homeOutline,
  calendarOutline,
  personOutline,
  home,
  calendar,
  person,
  settings,
  settingsOutline,
} from "ionicons/icons";

import { IonRippleEffect } from "@ionic/react";

function MenuSide() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const partesDoCaminho = pathname.split("/");
  const primeiroNomeDoCaminho = partesDoCaminho[2];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {windowWidth < 1000 ? (
        <div>
          <br />
          <br />
          <div
            style={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              bottom: 0,
              background: "#ffffff",
              height: 50,
            }}
          >
            <div className="row" style={{ width: "100%" }}>
              <div className="col-3">
                <div
                  onClick={() => navigate({ pathname: "/empresas/inicio" })}
                  className="ion-activatable ripple-parent rectangle"
                  style={{
                    color: "#7A9297",
                    opacity: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    height: 40,
                    borderRadius: "2rem",
                  }}
                >
                  <IonIcon
                    style={{ fontSize: 20 }}
                    icon={
                      primeiroNomeDoCaminho === "inicio" ? home : homeOutline
                    }
                  ></IonIcon>
                  <br />
                  <IonRippleEffect></IonRippleEffect>
                </div>
              </div>
              <div className="col-3">
                <div
                  onClick={() =>
                    navigate({ pathname: "/empresas/agendamentos" })
                  }
                  className="ion-activatable ripple-parent rectangle"
                  style={{
                    color: "#7A9297",
                    opacity: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    height: 40,
                    borderRadius: "2rem",
                  }}
                >
                  <IonIcon
                    style={{ fontSize: 20 }}
                    icon={
                      primeiroNomeDoCaminho === "agendamentos"
                        ? calendar
                        : calendarOutline
                    }
                  ></IonIcon>
                  <br />
                  <IonRippleEffect></IonRippleEffect>
                </div>
              </div>
              <div className="col-3">
                <div
                  onClick={() => navigate({ pathname: "/empresas/conta" })}
                  className="ion-activatable ripple-parent rectangle"
                  style={{
                    color: "#7A9297",
                    opacity: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    height: 40,
                    borderRadius: "2rem",
                  }}
                >
                  <IonIcon
                    style={{ fontSize: 20 }}
                    icon={
                      primeiroNomeDoCaminho === "conta" ? person : personOutline
                    }
                  ></IonIcon>
                  <br />
                  <IonRippleEffect></IonRippleEffect>
                </div>
              </div>
              <div className="col-3">
                <div
                  onClick={() =>
                    navigate({ pathname: "/empresas/configuracoes" })
                  }
                  className="ion-activatable ripple-parent rectangle"
                  style={{
                    color: "#7A9297",
                    opacity: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                    height: 40,
                    borderRadius: "2rem",
                  }}
                >
                  <IonIcon
                    style={{ fontSize: 20 }}
                    icon={
                      primeiroNomeDoCaminho === "configuracoes"
                        ? settings
                        : settingsOutline
                    }
                  ></IonIcon>
                  <br />
                  <IonRippleEffect></IonRippleEffect>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
export default MenuSide;
