import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlListar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonSearchbar,
  IonSpinner,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const [filtro, setFiltro] = useState("");

  const [pagina, setPagina] = useState(1);
  const [loading, setLoading] = useState(true);

  const [itens, setItens] = useState([]);
  const location = useLocation();

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url + `?pagina=${pagina}`, {
        token: tokenUsuario,
        nome: filtro,
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = partesDoCaminho[2];

    const url = urlListar(tipo, tela);
    const lista = await consultaLista(url);
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, [pagina, filtro]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin">
          <IonButton
            expand="block"
            color="secondary-light"
            onClick={() => {
              navigate({ pathname: "/empresas/sugestoes/adicionar" });
            }}
          >
            Adicionar novo
          </IonButton>
        </div>
        <IonSearchbar
          autocomplete="on"
          value={filtro}
          onIonChange={(e) => setFiltro(e.detail.value)}
          inputmode="text"
          placeholder={"Buscar informações"}
        ></IonSearchbar>
        <div className="card ion-margin">
          <h5
            style={{
              fontWeight: 500,
              fontSize: 15,
              color: "#13aad7",
              margin: 10,
              lineHeight: '20px',
            }}
          >
            Você pode sugerir a adição de algum recurso ou melhoria no
            Aplicativo. Caso seja aceito por nossa equipe, o mesmo irá aparecer
            na listagem logo abaixo.
          </h5>
        </div>
        {loading ? (
          <div className="ion-text-center" style={{ marginTop: "10vh" }}>
            <IonSpinner name="crescent" />
          </div>
        ) : (
          <div>
            {Array.isArray(itens) && (
              <div>
                <div>
                  <div className="row">
                    {itens.map((row, index) => (
                      <div className="col-sm-4" key={index}>
                        <IonItem
                          onClick={() => {
                            navigate({
                              pathname: "/empresas/sugestoes/info",
                              search: createSearchParams({
                                id: row.id,
                              }).toString(),
                            });
                          }}
                        >
                          <IonLabel>
                            <h5>Descrição: {row.dsc}</h5>
                          </IonLabel>
                        </IonItem>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="ion-margin-horizontal">
              <div className="row">
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
                      }}
                    >
                      Anterior
                    </IonButton>
                  </div>
                </div>
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => prevPagina + 1);
                      }}
                    >
                      Próximo
                    </IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
