import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import {
  calendarOutline,
  personOutline,
  addOutline,
  calendar,
  person,
  addCircle,
  powerOutline
} from "ionicons/icons";

import { IonRippleEffect } from "@ionic/react";
import { showActionSheet } from "../../utils/functions";

const logout = () => {
  document.cookie =
    "token_usuario_agendou" +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('tipoUsuario');
  window.location.href = "/";
};

const modalLogout = () => {
  showActionSheet("Deseja realmente sair da conta atual?", [
    {
      text: "Sim",
      handler: () => {
        logout();
      },
    },
    {
      text: "Cancelar",
      handler: () => {
        return;
      },
    },
  ]);
};

function MenuSide() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const partesDoCaminho = pathname.split("/");
  const primeiroNomeDoCaminho = partesDoCaminho[2];

  return (
    <div>
      <br /><br />
      <div
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          bottom: 0,
          background: "#ffffff",
          height: 50,
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/inicio" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 0,
                height: 40,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={primeiroNomeDoCaminho === "inicio" ?  addCircle: addOutline}
              ></IonIcon>
              <br />
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/agendamentos" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 0,
                height: 40,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={
                  primeiroNomeDoCaminho === "agendamentos"
                    ? calendar
                    : calendarOutline
                }
              ></IonIcon>
              <br />
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>
          <div className="col-3">
            <div
              onClick={() => navigate({ pathname: "/usuarios/conta" })}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 0,
                height: 40,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={
                  primeiroNomeDoCaminho === "conta" ? person : personOutline
                }
              ></IonIcon>
              <br />
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>
          <div className="col-3">
            <div
              onClick={() => {
                modalLogout()
              }}
              className="ion-activatable ripple-parent rectangle"
              style={{
                color: "#7A9297",
                opacity: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                padding: 0,
                height: 40,
                borderRadius: "2rem",
              }}
            >
              <IonIcon
                style={{ fontSize: 20 }}
                icon={powerOutline}
              ></IonIcon>
              <br />
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MenuSide;
