import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonTextarea,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

import { alertNotification } from "../../../utils/functions";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const [dsc, setDsc] = useState("");

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "sugestoes"), {
        token: tokenUsuario,
        dsc: dsc,
      });
      if (response) {
        const funcao = () => {
          navigate({ pathname: "/empresas/sugestoes" });
        };
        let tituloD = "Atenção";
        let dscD =
          "Recebemos sua solicitação, caso ela seja aprovada irá aparecer na área de sugestões!";
        alertNotification(tituloD, dscD, "OK", funcao);
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">
            Descrição da sugestão/melhoria:{" "}
          </IonLabel>
          <IonTextarea
            className="obs"
            placeholder="Descrição da sugestão/melhoria"
            onIonChange={(e) => setDsc(e.detail.value)}
            value={dsc}
            rows={15}
          ></IonTextarea>
        </IonItem>
        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
