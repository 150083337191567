import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  urlInformacoes,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import { IonPage, IonContent, IonCard } from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function ServicosEmpresas() {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const [dsc, setDsc] = useState("");

  const consultar = async () => {
    try {
      const url = urlInformacoes("empresas", "sugestoes", `id=${idPage}`);
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      let consulta = await consultar();
      setDsc(consulta[0].dsc);
    };
    run();
  }, []);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonCard>
          <div className="ion-margin">
            <h5>Descrição:</h5>
            <p>{dsc}</p>
          </div>
        </IonCard>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
