/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlLoginEmpresas } from "../../../utils/constants";
import axios from "axios";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonCard,
  IonContent,
} from "@ionic/react";

import { showActionSheet, toastNotificacao } from "../../../utils/functions";
export default function LoginUsuario() {
  const navigate = useNavigate();
  const [codigo, setcodigo] = useState(55);
  const [ddd, setDDD] = useState("");
  const [numero, setNumero] = useState("");

  const fazerLogin = async () => {
    try {
      const response = await axios.post(urlLoginEmpresas, {
        codigo: codigo,
        ddd: ddd,
        telefone: numero,
      });

      if (response.data.status == "1") {
        navigate({
          pathname: "/empresas/codigo_verificacao",
        });
      } else if (response.data.status == "2") {
        localStorage.setItem("codigo", codigo);
        localStorage.setItem("DDD", ddd);
        localStorage.setItem("numero", numero);
        navigate({
          pathname: "/empresas/cadastrar",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verificarCampos = async () => {
    if (ddd.length < 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (ddd.length > 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length < 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length > 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    showActionSheet(
      "O número +" + codigo + " " + ddd + numero + " está correto?",
      [
        {
          text: "Sim",
          handler: () => {
            fazerLogin();
          },
        },
        {
          text: "Fechar",
          handler: () => {
            return;
          },
        },
      ]
    );
  };

  const irParaCadastro = () => {
    navigate({
      pathname: "/usuarios/cadastrar",
    });
  };

  return (
    <IonContent>
      <div className="ion-margin">
        <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
            <IonCard>
              <div className="ion-margin centro" name="logarusuario">
                <div style={{ margin: "1%" }}>
                  <img
                    src="https://gerenciadores.com/apps/agendou/assets/img/logo.png"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "1rem",
                      margin: "2%",
                    }}
                  />
                </div>

                <h2 className="nomeDoApp"></h2>

                <IonText color="medium">
                  <p className="IonText-center">
                    Preencha os campos abaixo para continuar
                  </p>
                </IonText>

                <IonItem>
                  <IonLabel position="stacked">Código do país: </IonLabel>
                  <IonInput
                    type="tel"
                    className="codigo"
                    maxlength={"5"}
                    minlength={"2"}
                    placeholder="Código do país"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setcodigo(event.target.value);
                    }}
                    value={codigo}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">DDD: </IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={"2"}
                    minlength={"2"}
                    className="ddd"
                    placeholder="Digite seu DDD"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setDDD(event.target.value);
                    }}
                    value={ddd}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Número: </IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={"9"}
                    minlength={"9"}
                    className="numero"
                    placeholder="Digite seu número"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setNumero(event.target.value);
                    }}
                    value={numero}
                  />
                </IonItem>

                <IonButton
                  color="secondary-light"
                  onClick={verificarCampos}
                  expand="full"
                  className="botaoLogar"
                >
                  Entrar &nbsp; <i className="ri-shield-check-line"></i>
                </IonButton>

                <IonText>
                  <p>Ainda não tem conta?</p>
                </IonText>

                <IonButton
                  color="light"
                  onClick={irParaCadastro}
                  expand="full"
                  className=""
                >
                  Cadastre-se &nbsp; <i className="ri-shield-check-line"></i>
                </IonButton>
              </div>
            </IonCard>
          </div>

          <div className="col-sm-4"></div>
        </div>
      </div>
    </IonContent>
  );
}
