import React, { useState, useEffect } from "react";
import axios from "axios";
import { IonCard, IonItem, IonLabel, IonToggle, IonInput } from "@ionic/react";

import { urlAlterar, tokenUsuario } from "../../../utils/constants";
import { toastNotificacao } from "../../../utils/functions";

export default function DiaSemana({ horario, atualizar, resetAtualizar }) {
  const [dia, setDia] = useState(horario.dia);
  const [horario1, setHorario1] = useState(horario.horario_inicial_1);
  const [horario2, setHorario2] = useState(horario.horario_final_1);
  const [horario3, setHorario3] = useState(horario.horario_inicial_2);
  const [horario4, setHorario4] = useState(horario.horario_final_2);
  const [status, setStatus] = useState(horario.status);

  useEffect(()=>{
    setDia(horario.dia);
  },[]);

  function obterNomeDoDia(numeroDoDia) {
    const diasDaSemana = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];

    if (numeroDoDia >= 0 && numeroDoDia <= 6) {
      return diasDaSemana[numeroDoDia];
    } else {
      return "Dia inválido";
    }
  }

  const atualizarDia = async () => {
    try {
      const response = await axios.post(
        urlAlterar("empresas", "configuracoes_horario_atendimento"),
        {
          token: tokenUsuario,
          dia: dia,
          status: status,
          horario_inicial_1: horario1,
          horario_final_1: horario2,
          horario_inicial_2: horario3,
          horario_final_2: horario4,
        }
      );
      if(response.data === 6){
        await toastNotificacao(
          "success",
          "alert-outline",
          "Horários atualizados com sucesso!",
          3000,
          "Ok"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (atualizar === 1) {
      atualizarDia();
      if(dia === 6){
        resetAtualizar(0);
      }
    }
  }, [atualizar,dia]);

  return (
    <IonCard>
      <IonItem>
        <IonLabel>{obterNomeDoDia(dia)}</IonLabel>
        {status == 1 ? (
          <IonToggle
            checked="true"
            slot="end"
            color="primary"
            mode="ios"
            onIonChange={() => setStatus(0)}
          ></IonToggle>
        ) : (
          <IonToggle
            slot="end"
            color="primary"
            mode="ios"
            onIonChange={() => setStatus(1)}
          ></IonToggle>
        )}
      </IonItem>

      <div className="ion-margin">
        <div className="row">
          <div className="col-12">
            <br />
            <IonLabel className="ion-padding" style={{ fontSize: "12px" }}>
              Horários referentes ao primeiro período do dia.
            </IonLabel>
            <br />
          </div>
          <div className="col-6">
            <IonItem>
              <IonLabel position="stacked">De: </IonLabel>
              <IonInput
                type="time"
                onIonChange={(e) => setHorario1(e.detail.value)}
                value={horario1}
              />
            </IonItem>
          </div>
          <div className="col-6">
            <IonItem>
              <IonLabel position="stacked">Até: </IonLabel>
              <IonInput
                type="time"
                onIonChange={(e) => setHorario2(e.detail.value)}
                value={horario2}
              />
            </IonItem>
          </div>
          <div className="col-12">
            <br />
            <IonLabel className="ion-padding" style={{ fontSize: "12px" }}>
              Horários referentes ao segundo período do dia.
            </IonLabel>
            <br />
          </div>
          <div className="col-6">
            <IonItem>
              <IonLabel position="stacked">De: </IonLabel>
              <IonInput
                type="time"
                onIonChange={(e) => setHorario3(e.detail.value)}
                value={horario3}
              />
            </IonItem>
          </div>
          <div className="col-6">
            <IonItem>
              <IonLabel position="stacked">Até: </IonLabel>
              <IonInput
                type="time"
                onIonChange={(e) => setHorario4(e.detail.value)}
                value={horario4}
              />
            </IonItem>
          </div>
        </div>
      </div>
    </IonCard>
  );
}
