import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlListarCalendario, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonPage,
  IonContent,
  IonButton,
  IonItem,
  IonCol,
  IonRow,
  IonLabel,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";

import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function CalendarioEmpresas() {
  const navigate = useNavigate();

  const [itens, setItens] = useState([]);
  const [diaBuscado, setDiaBuscado] = useState(moment().format("YYYY-MM-DD"));
  const [diaF, setDiaF] = useState(moment().format("YYYY-MM-DD"));

  const [datasBusca, setDatasBusca] = useState(getWeekDates(diaBuscado));
  const [agendamentos, setAgendamentos] = useState([]);

  function getWeekDates(date) {
    const inputDate = moment(date);
    const startOfWeek = inputDate.clone().startOf("week");
    const weekDates = [];

    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
    }

    return {
      weekDates,
    };
  }

  const proximaSemana = () => {
    let dia = moment(diaF).add(7, "days").format("YYYY-MM-DD");
    setDiaF(dia);
  };

  const semanaAnterior = () => {
    let dia = moment(diaF).subtract(7, "days").format("YYYY-MM-DD");
    setDiaF(dia);
  };

  function filterByDate(date, array) {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return array.filter(
      (item) => moment(item.dia).format("YYYY-MM-DD") === formattedDate
    );
  }

  const consultaLista = async (datas) => {
    try {
      const response = await axios.post(urlListarCalendario, {
        token: tokenUsuario,
        datas: datas,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const consultarItens = async (datas) => {
    const lista = await consultaLista(datas);
    setItens(lista);
  };

  useEffect(() => {
    let datasB = getWeekDates(diaF);
    if (datasB.weekDates) {
      consultarItens(datasB.weekDates);
      setDatasBusca(datasB);
    }
  }, [diaF]);

  useEffect(() => {
    const agendamentosData = filterByDate(diaBuscado, itens);

    if (agendamentosData.length > 0 && agendamentosData[0].agendamentos) {
      const originalData = agendamentosData[0].agendamentos;
      const orderedData = {};
      let newIndex = 0;

      for (const key in originalData) {
        orderedData[newIndex] = originalData[key];
        newIndex++;
      }

      const orderedArray = Object.values(orderedData);

      setAgendamentos(orderedArray);
    }
  }, [itens, diaBuscado, agendamentos]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin-horizontal">
          <h1 className="tituloPagina" style={{ textTransform: "capitalize" }}>
            {moment(diaF).format("MMMM")} {moment(diaF).format("YYYY")}
          </h1>
        </div>
        <div className="rowSemana">
          {datasBusca.weekDates.map((dia, index) => (
            <div style={{ width: "14%" }} key={index}>
              <IonButton
                style={{ height: "70px" }}
                data-dia={moment(dia).format("YYYY-MM-DD")}
                color={
                  moment(dia).format("YYYY-MM-DD") ===
                  moment(diaBuscado).format("YYYY-MM-DD")
                    ? "secondary-light"
                    : "light"
                }
                expand="full"
                className="botaoDiaSemana"
                onClick={() => {
                  setDiaBuscado(dia);
                }}
              >
                {moment(dia).format("dddd").toUpperCase().substring(0, 3)}
                <br />
                <br />
                {moment(dia).format("D")}
              </IonButton>
            </div>
          ))}
        </div>
        <div
          style={{
            backgroundColor: "#f4f5f8",
            borderRadius: "11px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IonButton color="light" expand="full" onClick={semanaAnterior}>
            <i className="ri-arrow-left-s-line icone19px"></i> &nbsp;&nbsp;
          </IonButton>
          <IonButton color="light" expand="full" onClick={proximaSemana}>
            &nbsp;&nbsp; <i className="ri-arrow-right-s-line icone19px"></i>
          </IonButton>
        </div>
        {Array.isArray(agendamentos) && (
          <div>
            <IonRow>
              {agendamentos.map((row, index) => (
                <IonCol sizeSm="4" key={index}>
                   <IonItem
                  onClick={() => {
                    navigate({
                      pathname: "/empresas/agendamento",
                      search: createSearchParams({
                        id: row.id,
                      }).toString(),
                    });
                  }}
                  key={index}
                >
                  <IonLabel>
                    <h5
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 700,
                      }}
                    >
                      {moment(row.data_agendamento).format("D")} de{" "}
                      {moment(row.data_agendamento).format("MMMM")} de{" "}
                      {moment(row.data_agendamento).format("YYYY")} :{" "}
                      {moment("2023-01-01 " + row.horario_inicial).format(
                        "HH:mm"
                      )}{" "}
                      -{" "}
                      {moment("2023-01-01 " + row.horario_final).format(
                        "HH:mm"
                      )}
                    </h5>
                    <h5>Serviço: {row.servico}</h5>
                    <h5>Cliente: {row.cliente_nome}</h5>
                    <StatusAgendamento status={row.status} />
                    <p style={{ marginTop: "7px", marginBottom: "5px" }}>
                      <i className="ri-information-line"></i> &nbsp; {row.obs}
                    </p>
                  </IonLabel>
                </IonItem>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
