import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import {
  urlAgendamentosDiaEmpresas,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import { IonList, IonItem, IonLabel, IonPage, IonContent } from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";

import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function AgendamentosDiaUsuario() {
  const navigate = useNavigate();

  const [itens, setItens] = useState([]);

  const consultaLista = async () => {
    try {
      const response = await axios.post(urlAgendamentosDiaEmpresas, {
        token: tokenUsuario,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.agendamentos_dia;
    } catch (error) {
      console.log(error);
    }
  };

  const consultarItens = async () => {
    const lista = await consultaLista();
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, []);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        {Array.isArray(itens) && (
          <div>
            <div>
              <div>
                <IonList>
                  {itens.map((row, index) => (
                    <IonItem
                      onClick={() => {
                        navigate({
                          pathname: "/empresas/agendamento",
                          search: createSearchParams({
                            id: row.id,
                          }).toString(),
                        });
                      }}
                      key={index}
                    >
                      <IonLabel>
                        <h5
                          style={{
                            textTransform: "capitalize",
                            fontWeight: 700,
                          }}
                        >
                          {moment(row.data_agendamento).format("D")} de{" "}
                          {moment(row.data_agendamento).format("MMMM")} de{" "}
                          {moment(row.data_agendamento).format("YYYY")} :{" "}
                          {moment("2023-01-01 " + row.horario_inicial).format(
                            "HH:mm"
                          )}{" "}
                          -{" "}
                          {moment("2023-01-01 " + row.horario_final).format(
                            "HH:mm"
                          )}
                        </h5>
                        <h5>Serviço: {row.servico}</h5>
                        <h5>Cliente: {row.cliente_nome}</h5>
                        <StatusAgendamento status={row.status} />
                        <p style={{ marginTop: "7px", marginBottom: "5px" }}>
                          <i className="ri-information-line"></i> &nbsp;{" "}
                          {row.obs}
                        </p>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
