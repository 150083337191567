import React from 'react';
import { IonApp } from '@ionic/react';
import '@ionic/react/css/core.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Usuários

import LoginUsuario from './pages/usuarios/login';
import CadastrarUsuario from './pages/usuarios/cadastrar';

import AgendamentosUsuario from './pages/usuarios/agendamentos';
import AgendamentoUsuario from './pages/usuarios/agendamento';
import AgendarUsuario from './pages/usuarios/agendar';

import ContaUsuario from './pages/usuarios/conta';

import CodigoVerificacaoUsuario from './pages/usuarios/codigo_verificacao';

//Empresas

import LoginEmpresas from './pages/empresas/login';
import CadastrarEmpresas from './pages/empresas/cadastrar';

import InicioEmpresas from './pages/empresas/inicio';

import AgendamentosEmpresas from './pages/empresas/agendamentos';
import AgendamentoEmpresas from './pages/empresas/agendamento';
import AgendarEmpresas from './pages/empresas/agendar';
import AgendamentosDiaEmpresas from './pages/empresas/agendamentos_dia';

import ContaEmpresas from './pages/empresas/conta';

import CodigoVerificacaoEmpresas from './pages/empresas/codigo_verificacao';


import ServicosEmpresas from './pages/empresas/servicos';
import ServicosAdicionarEmpresas from './pages/empresas/servicos/adicionar';
import ServicosEditarEmpresas from './pages/empresas/servicos/editar';

import ClientesEmpresas from './pages/empresas/clientes';
import ClientesAdicionarEmpresas from './pages/empresas/clientes/adicionar';
import ClientesEditarEmpresas from './pages/empresas/clientes/editar';

import DiasNaoAtendidosEmpresas from './pages/empresas/dias_nao_atendidos';
import DiasNaoAtendidosAdicionarEmpresas from './pages/empresas/dias_nao_atendidos/adicionar';
import DiasNaoAtendidosEditarEmpresas from './pages/empresas/dias_nao_atendidos/editar';

import HorarioAtendimentoEmpresas from './pages/empresas/horario_atendimento';

import ConfiguracoesEmpresa from './pages/empresas/configuracoes';

import CalendarioSemanaEmpresas from './pages/empresas/calendario';

import Dashboard from './pages/empresas/dashboard';

import CalendarioAgenda from './pages/empresas/calendario_agenda';

import ConsumoUsuario from './pages/empresas/inicio/consumo_mensal';

import SuporteEmpresas from './pages/empresas/suporte';
import InfoSuporteEmpresas from './pages/empresas/suporte/editar';
import AdicionarSuporteEmpresas from './pages/empresas/suporte/adicionar';

import SugestoesEmpresas from './pages/empresas/sugestoes';
import InfoSugestoesEmpresas from './pages/empresas/sugestoes/editar';
import AdicionarSugestoesEmpresas from './pages/empresas/sugestoes/adicionar';

//Global

import NotFound from './pages/not_found';
import Escolha from './pages/escolha';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './App.css';

export default function App() {

  return (
    <IonApp>
      <Router>
          <Routes>

            <Route path="/" element={<Escolha />} />

            <Route path="/usuarios/login" element={<LoginUsuario />} />
            <Route path="/empresa" element={<LoginUsuario />} />
           
            <Route path="/usuarios/cadastrar" element={<CadastrarUsuario />} />
            <Route path="/usuarios/inicio" element={<AgendarUsuario />} />
            <Route path="/usuarios/agendamentos" element={<AgendamentosUsuario />} />
            <Route path="/usuarios/conta" element={<ContaUsuario />} />
            
            <Route path="/usuarios/agendamento" element={<AgendamentoUsuario />} />
            <Route path="/usuarios/agendar" element={<AgendarUsuario />} />
            <Route path="/usuarios/codigo_verificacao" element={<CodigoVerificacaoUsuario />} />

            <Route path="/empresas/login" element={<LoginEmpresas />} />
            <Route path="/empresas/cadastrar" element={<CadastrarEmpresas />} />
            
            <Route path="/empresas/inicio" element={<InicioEmpresas />} />
            <Route path="/empresas/consumo" element={<ConsumoUsuario />} />
            
            <Route path="/empresas/agendamentos" element={<AgendamentosEmpresas />} />
            <Route path="/empresas/agendamentos-dia" element={<AgendamentosDiaEmpresas />} />
            <Route path="/empresas/conta" element={<ContaEmpresas />} />
            
            <Route path="/empresas/agendamento" element={<AgendamentoEmpresas />} />
            <Route path="/empresas/agendar" element={<AgendarEmpresas />} />
            <Route path="/empresas/codigo_verificacao" element={<CodigoVerificacaoEmpresas />} />

            <Route path="/empresas/servicos" element={<ServicosEmpresas />} />
            <Route path="/empresas/servicos/adicionar" element={<ServicosAdicionarEmpresas />} />
            <Route path="/empresas/servicos/editar" element={<ServicosEditarEmpresas />} />
         
            <Route path="/empresas/clientes" element={<ClientesEmpresas />} />
            <Route path="/empresas/clientes/adicionar" element={<ClientesAdicionarEmpresas />} />
            <Route path="/empresas/clientes/editar" element={<ClientesEditarEmpresas />} />
         
            <Route path="/empresas/dias_nao_atendidos" element={<DiasNaoAtendidosEmpresas />} />
            <Route path="/empresas/dias_nao_atendidos/adicionar" element={<DiasNaoAtendidosAdicionarEmpresas />} />
            <Route path="/empresas/dias_nao_atendidos/editar" element={<DiasNaoAtendidosEditarEmpresas />} />
         
            <Route path="/empresas/configuracoes_horario_atendimento" element={<HorarioAtendimentoEmpresas />} />
         
            <Route path="/empresas/configuracoes" element={<ConfiguracoesEmpresa />} />
         
            <Route path="/empresas/calendario-semana" element={<CalendarioSemanaEmpresas />} />
            <Route path="/empresas/calendario" element={<CalendarioAgenda />} />
         
            <Route path="/empresas/dashboard" element={<Dashboard />} />
            
            <Route path="/empresas/suporte" element={<SuporteEmpresas />} />
            <Route path="/empresas/suporte/info" element={<InfoSuporteEmpresas />} />
            <Route path="/empresas/suporte/adicionar" element={<AdicionarSuporteEmpresas />} />
            
            <Route path="/empresas/sugestoes" element={<SugestoesEmpresas />} />
            <Route path="/empresas/sugestoes/info" element={<InfoSugestoesEmpresas />} />
            <Route path="/empresas/sugestoes/adicionar" element={<AdicionarSugestoesEmpresas />} />
         
            <Route path="*" element={<NotFound />} />

          </Routes>
        </Router>
    </IonApp>
  );
}