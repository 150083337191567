import axios from "axios";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const apiUrl = process.env.REACT_APP_API_BASE_URL;

//Usuários
export const urlLoginUsuario = apiUrl + 'usuarios/entrar/adicionar';
export const urlCadastrarUsuario = apiUrl + 'usuarios/cadastrar/adicionar';
export const urlCodigoVerificacaoUsuario = apiUrl + 'usuarios/codigo_verificacao/listar';
export const urlInfoContaUsuario = apiUrl + 'usuarios/consultas/conta';
export const urlHorariosAtendimentoUsuario = apiUrl + 'usuarios/agendar/horario_atendimento';
export const urlHorariosDisponiveisAgendamentoUsuario = apiUrl + '/usuarios/agendamentos/horarios_servicos_removidos';
export const urlServicosAgendamentoUsuario = apiUrl + 'usuarios/consultas/servicos';
export const urlCancelarAgendamentoUsuario = apiUrl + 'usuarios/agendamentos/cancelar';
export const urlAgendamentosDiaUsuario = apiUrl + 'usuarios/inicio/resumo';
export const urlContaUsuario = apiUrl + 'usuarios/consultas/conta';
export const urlEnviarCodigoPerfilUsuario = apiUrl + 'usuarios/inicio/listar';
export const urlAtualziarCodigoPerfilUsuario = apiUrl + 'usuarios/inicio/editar';

//Empresas
export const urlLoginEmpresas = apiUrl + 'empresas/entrar/adicionar';
export const urlCadastrarEmpresas = apiUrl + 'empresas/cadastrar/adicionar';
export const urlCodigoVerificacaoEmpresas = apiUrl + 'empresas/codigo_verificacao/listar';
export const urlInfoContaEmpresas = apiUrl + 'empresas/consultas/conta';
export const urlHorariosAtendimentoEmpresas = apiUrl + 'empresas/agendar/horario_atendimento';
export const urlHorariosDisponiveisAgendamentoEmpresas = apiUrl + '/empresas/agendamentos/horarios_servicos_removidos';
export const urlServicosAgendamentoEmpresas = apiUrl + 'empresas/consultas/servicos';
export const urlCancelarAgendamentoEmpresas = apiUrl + 'empresas/agendamentos/cancelar';
export const urlAgendamentosDiaEmpresas = apiUrl + 'empresas/inicio/resumo';
export const urlContaEmpresas = apiUrl + 'empresas/consultas/conta';
export const urlEnviarCodigoPerfilEmpresas = apiUrl + 'empresas/conta/listar';
export const urlAtualziarCodigoPerfilEmpresas = apiUrl + 'empresas/conta/editar';
export const urlClientesAgendamento = apiUrl + 'empresas/consultas/clientes';
export const urlAssinarPlano = apiUrl + 'empresas/inicio/adicionar';
export const urlListarCalendario = apiUrl + 'empresas/calendario/listar';
export const urlListarCalendarioAgenda = apiUrl + 'empresas/calendario/listar_calendario';
export const urlAgendamentosAlterarStatus = apiUrl + 'empresas/agendamentos/editar_status';

export const urlAgendamentosPorDia = apiUrl + 'empresas/dashboard/listar';
export const urlResumoMensalConta = apiUrl + 'empresas/consultas/resumo_conta_mensal';

//Token
export let tokenUsuario = getCookie("token_usuario_agendou");
export let modoDev = false;
if(apiUrl.includes('dev')){ 
   modoDev = true;
   tokenUsuario = cookies.get('token_usuario_agendou');
}
// Gráficos usuários
export const urlGraficoTotalAgendamentosUsuario = apiUrl + 'usuarios/inicio/grafico_total_agendamentos';

//Urls base
export const urlListar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/listar`;
}
export const urlAdicionar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/adicionar`;
}
export const urlInformacoes = (tipoUsuario, tipoTela, params) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/get_id?${params}`;
}
export const urlAlterar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/editar`;
}
export const urlApagar = (tipoUsuario, tipoTela) => {
    return apiUrl + `${tipoUsuario}/${tipoTela}/apagar`;
}

//Sessão do usuário

function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookiePair = cookieArray[i].split('=');
        if (name == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}

export const carregarSessao = async () => {
    let telaAtual = window.location.pathname.split("/")[1];
    if(telaAtual === 'empresa') {
        telaAtual = 'usuarios';
    }
    try {
        const response = await axios.post(apiUrl + telaAtual + '/sessao/sessao', {
            token: tokenUsuario,
        });
        if (response.data === 1) {
            return true;
         } else {
             return null;
         }
    } catch(error) {
        return null;
    }
};

const verificarSessao = async () => {
    let telaAtualInicial = window.location.pathname.split("/")[1];
    let telaAtual = window.location.pathname.split("/")[2];
    let sessao = await carregarSessao();
       
    if(telaAtualInicial !== '' && telaAtualInicial !== 'empresa'){
       
        if(telaAtualInicial === 'usuarios') {
            if(!sessao) {
                if(telaAtual !== 'login' && telaAtual !== 'codigo_verificacao' && telaAtual !== 'cadastrar' && telaAtualInicial !== 'empresa') {
                   // window.location.href = '/usuarios/login';
                }
            } else {
                if(telaAtual === 'login') {
                   // window.location.href = '/usuarios/inicio';
                } 
            }
        } else {
            if(!sessao) {
                if(telaAtual !== 'login' && telaAtual !== 'codigo_verificacao' && telaAtual !== 'cadastrar' && telaAtualInicial !== 'empresa') {
                    //window.location.href = '/empresas/login';
                }
            } else {
                if(telaAtual === 'login') {
                    //window.location.href = '/empresas/inicio';
                }
            }
        }
    } else {
        if(telaAtualInicial === 'empresa') {
            if(sessao) {
                //window.location.href = '/usuarios/inicio';
            }
        }
    }
}

verificarSessao();
