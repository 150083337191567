import React, { useEffect } from "react";
import { IonCard, IonText, IonButton, IonContent } from "@ionic/react";

import { useNavigate } from "react-router-dom";

export default function Escolha() {
  const navigate = useNavigate();
  const tipoUsuario = localStorage.getItem("tipoUsuario");
  useEffect(() => {
    if(tipoUsuario === 'cliente'){
      navigate({ pathname: 'usuarios/inicio' });
    } else if(tipoUsuario === 'empresa') {
      navigate({ pathname: 'empresas/inicio' });
    } else {
      return;
    }
  }, [tipoUsuario]);

  return (
    <IonContent>
      <div className="ion-margin">
        <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
            <IonCard>
              <div className="ion-margin centro" name="logarusuario">
                <div style={{ margin: "1%" }}>
                  <img
                    src="https://gerenciadores.com/apps/agendou/assets/img/logo.png"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "1rem",
                      margin: "2%",
                    }}
                  />
                </div>

                <h2 className="nomeDoApp"></h2>

                <IonText color="medium">
                  <p className="IonText-center">
                    Escolha uma das opções abaixo
                  </p>
                </IonText>

                <IonButton
                  color="light"
                  onClick={() => {
                    navigate({ pathname: "usuarios/login" });
                    localStorage.setItem("tipoUsuario", "cliente");
                  }}
                  expand="full"
                  className="botaoLogar"
                >
                  Sou cliente &nbsp; <i className="ri-shield-check-line"></i>
                </IonButton>

                <IonButton
                  color="secondary-light"
                  onClick={() => {
                    navigate({ pathname: "empresas/login" });
                    localStorage.setItem("tipoUsuario", "empresa");
                  }}
                  expand="full"
                  className=""
                >
                  Sou profissional &nbsp;{" "}
                  <i className="ri-shield-check-line"></i>
                </IonButton>
              </div>
            </IonCard>
          </div>

          <div className="col-sm-4"></div>
        </div>
      </div>
    </IonContent>
  );
}
