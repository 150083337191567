import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { urlCadastrarUsuario } from "../../../utils/constants";
import axios from "axios";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonCard,
  IonButtons,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
} from "@ionic/react";

import {
  showActionSheet,
  toastNotificacao,
  validarEmail,
} from "../../../utils/functions";

export default function CadastrarUsuario() {
  const navigate = useNavigate();
  const [codigo, setCodigo] = useState(localStorage.getItem("codigo"));
  const [ddd, setDDD] = useState(localStorage.getItem("DDD"));
  const [numero, setNumero] = useState(localStorage.getItem("numero"));
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");

  const [modalTermos, setModalTermos] = useState(false);
  const [modalPoliticas, setModalPoliticas] = useState(false);

  const url = window.location.href;
  const urlBase = new URL(url);

  const cadastrarUsuario = async () => {
    try {
      const response = await axios.post(urlCadastrarUsuario, {
        nome: nome,
        usuario: email,
        ddd: ddd,
        telefone: numero,
        codigo: codigo,
        nome_usuario: urlBase.searchParams.get("empresa"),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      let statusResponse = response.data.status;

      if (statusResponse == "usuario_existe") {
        return toastNotificacao(
          "danger",
          "alert-outline",
          "Já existe um usuário cadastrado com esse número",
          10000,
          "Ok"
        );
      } else if (statusResponse == "cadastro_efetuado") {
        toastNotificacao(
          "success",
          "alert-outline",
          "Cadastro efetuado com sucesso. Aguarde...",
          10000,
          "Ok"
        );
        navigate({
          pathname: "/usuarios/codigo_verificacao",
        });
      } else {
        return toastNotificacao(
          "danger",
          "alert-outline",
          "Ocorreu um erro inesperado, caso persista entre em contato com o suporte.",
          10000,
          "Ok"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const verificarCampos = async () => {
    if (!ddd && !numero && !codigo && !email && !nome) {
      return;
    }
    if ((ddd.length !== null) & (ddd.length < 2)) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (ddd.length > 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length < 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length > 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (nome.length === 0) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo nome",
        10000,
        "Ok"
      );
    }

    if (email.length === 0) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo e-mail corretamente",
        10000,
        "Ok"
      );
    }

    if (!validarEmail(email)) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Preencha o campo e-mail corretamente",
        10000,
        "Ok"
      );
    }

    showActionSheet(
      "O número +" + codigo + " " + ddd + numero + " está correto?",
      [
        {
          text: "Sim",
          handler: () => {
            cadastrarUsuario();
          },
        },
        {
          text: "Fechar",
          handler: () => {
            return;
          },
        },
      ]
    );
  };

  return (
    <IonPage>
      <IonContent>
        <div className="ion-margin">
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-4">
              <IonCard>
                <div className="ion-margin centro" name="logarusuario">
                  <div style={{ margin: "1%" }}>
                    <img
                      src="https://gerenciadores.com/apps/agendou/assets/img/logo.png"
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "1rem",
                        margin: "2%",
                      }}
                    />
                  </div>

                  <h2 className="nomeDoApp"></h2>

                  <IonText color="medium">
                    <p className="IonText-center">
                      Preencha os campos abaixo para continuar
                    </p>
                  </IonText>

                  <br />

                  <div className="esquerda">
                    <IonItem>
                      <IonLabel position="stacked">Código do país: </IonLabel>
                      <IonInput
                        type="tel"
                        className="codigo"
                        placeholder="Código do país"
                        onIonInput={(event) => {
                          event.preventDefault();
                          setCodigo(event.target.value);
                        }}
                        value={codigo}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked">Seu DDD: </IonLabel>
                      <IonInput
                        type="tel"
                        className="ddd"
                        placeholder="Informe seu DDD"
                        onIonInput={(event) => {
                          event.preventDefault();
                          setDDD(event.target.value);
                        }}
                        value={ddd}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked">Seu número: </IonLabel>
                      <IonInput
                        type="tel"
                        className="numero"
                        placeholder="Informe seu número"
                        onIonInput={(event) => {
                          event.preventDefault();
                          setNumero(event.target.value);
                        }}
                        value={numero}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked">
                        Seu Nome e Sobrenome:{" "}
                      </IonLabel>
                      <IonInput
                        type="text"
                        className="nome"
                        placeholder="Seu Nome"
                        onIonInput={(event) => {
                          event.preventDefault();
                          setNome(event.target.value);
                        }}
                        value={nome}
                      />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="stacked">Seu e-mail: </IonLabel>
                      <IonInput
                        type="email"
                        className="usuario"
                        placeholder="Seu e-mail"
                        onIonInput={(event) => {
                          event.preventDefault();
                          setEmail(event.target.value);
                        }}
                        value={email}
                      />
                    </IonItem>
                  </div>

                  <IonText>
                    <p>
                      Ao continuar, você concorda com os{" "}
                      <b
                        style={{ color: "#14aad8", cursor: "pointer" }}
                        onClick={() => {
                          setModalTermos(true);
                        }}
                      >
                        Termos
                      </b>{" "}
                      e{" "}
                      <b
                        onClick={() => setModalPoliticas(true)}
                        style={{ color: "#14aad8", cursor: "pointer" }}
                      >
                        Política de Privacidade
                      </b>
                      .
                    </p>
                  </IonText>

                  <IonButton
                    onClick={() => verificarCampos()}
                    color="secondary-light"
                    expand="full"
                    className=""
                  >
                    Confirmar &nbsp; <i className="ri-shield-check-line"></i>
                  </IonButton>
                </div>
              </IonCard>
            </div>

            <div className="col-sm-4"></div>
          </div>
        </div>

        {modalTermos ? (
          <IonModal
            className="modalTermos"
            isOpen={true}
            mode="ios"
            backdropDismiss="false"
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onclick={() => setModalTermos(false)}>
                    Fechar
                  </IonButton>
                </IonButtons>
                <IonTitle>Termos de uso</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <p>1.0 Propriedade Intelectual.</p>

              <p>
                <strong>O LICENCIADO</strong> n&atilde;o adquire, pelo presente
                instrumento ou pela utiliza&ccedil;&atilde;o do SOFTWARE, nenhum
                direito de propriedade intelectual ou outros direitos
                exclusivos, incluindo patentes, desenhos, marcas, direitos
                autorais ou quaisquer direitos sobre informa&ccedil;&otilde;es
                confidenciais ou segredos de neg&oacute;cio, bem como todo o
                conte&uacute;do disponibilizado no Site, incluindo, mas
                n&atilde;o se limitando a textos, gr&aacute;ficos, imagens,
                logotipos, &iacute;cones, fotografias, conte&uacute;do
                editorial, notifica&ccedil;&otilde;es, softwares e qualquer
                outro material, sobre ou relacionados ao SOFTWARE ou nenhuma
                parte dele. O LICENCIADO tamb&eacute;m n&atilde;o adquire nenhum
                direito sobre ou relacionado ao SOFTWARE ou qualquer componente
                dele, al&eacute;m dos direitos expressamente licenciados ao
                LICENCIADO sob o presente EULA ou em qualquer outro contrato
                mutuamente acordado por escrito entre o LICENCIADO e a
                LICENCIANTE. Quaisquer direitos n&atilde;o expressamente
                concedidos sob o presente instrumento s&atilde;o reservados.
              </p>

              <p>&nbsp;</p>

              <p>
                Tamb&eacute;m ser&aacute; de propriedade exclusiva da
                LICENCIANTE ou est&aacute; devidamente licenciado, todo o
                conte&uacute;do disponibilizado no Site, incluindo, mas
                n&atilde;o se limitando a, textos, gr&aacute;ficos, imagens,
                logos, &iacute;cones, fotografias, conte&uacute;do editorial,
                notifica&ccedil;&otilde;es, softwares e qualquer outro material.
              </p>

              <p>&nbsp;</p>

              <p>
                2.0 Declara&ccedil;&otilde;es do <strong>LICENCIADO</strong>
              </p>

              <p>
                <strong>O LICENCIADO</strong> declara ter pleno conhecimento dos
                direitos e obriga&ccedil;&otilde;es decorrentes do presente
                EULA, constituindo este instrumento o acordo completo entre as
                partes. Declara, ainda, ter lido, compreendido e aceito todos os
                seus termos e condi&ccedil;&otilde;es.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>O LICENCIADO</strong> declara que foi devidamente
                informado da pol&iacute;tica de confidencialidade e ambientes de
                prote&ccedil;&atilde;o de informa&ccedil;&otilde;es
                confidenciais, dados pessoais e registros de acesso da
                LICENCIANTE, consentindo livre e expressamente &agrave;s
                a&ccedil;&otilde;es de coleta, uso, armazenamento e tratamento
                das referidas informa&ccedil;&otilde;es e dados.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>O LICENCIADO</strong> declara estar ciente de que as
                opera&ccedil;&otilde;es que correspondam &agrave;
                aceita&ccedil;&atilde;o do presente EULA, de determinadas
                condi&ccedil;&otilde;es e op&ccedil;&otilde;es, bem como
                eventual rescis&atilde;o do presente instrumento e demais
                altera&ccedil;&otilde;es, ser&atilde;o registradas nos bancos de
                dados da LICENCIANTE, juntamente com a data e hora em que foram
                realizadas pelo LICENCIADO, podendo tais
                informa&ccedil;&otilde;es serem utilizadas como prova pelas
                partes, independentemente do cumprimento de qualquer outra
                formalidade.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>O LICENCIADO</strong> declara ainda que est&aacute;
                ciente de que para usufruir de algumas das funcionalidades do
                SOFTWARE, em especial, dos servi&ccedil;os de
                integra&ccedil;&atilde;o com a rede banc&aacute;ria,
                dever&aacute; disponibilizar &agrave; LICENCIANTE as
                INFORMA&Ccedil;&Otilde;ES DE CONTA para que o SOFTWARE, de
                maneira automatizada, colete informa&ccedil;&otilde;es
                diretamente nos sites e/ou outros meios virtuais
                disponibilizados pelas institui&ccedil;&otilde;es financeiras,
                com as quais mantenha relacionamento, agindo a LICENCIANTE,
                neste caso, como representante e mandat&aacute;ria do LICENCIADO
                nestes atos.
              </p>

              <p>&nbsp;</p>

              <p>3.0 Cadastro</p>

              <p>
                Acessando ao site e fazendo o cadastro em &ldquo;Teste
                Gr&aacute;tis&rdquo;, o LICENCIADO deve completar seu cadastro,
                fornecendo certos dados e informa&ccedil;&otilde;es. Esses dados
                e informa&ccedil;&otilde;es s&atilde;o protegidos nos termos da
                Lei e da Pol&iacute;tica de Privacidade.
              </p>

              <p>&nbsp;</p>

              <p>
                3.1 O LICENCIADO &eacute; o &uacute;nico respons&aacute;vel pela
                veracidade, integridade e corre&ccedil;&atilde;o das
                informa&ccedil;&otilde;es prestadas e deve manter seu Cadastro
                devidamente atualizado. O Cadastro requer a
                cria&ccedil;&atilde;o de um nome de usu&aacute;rio e uma senha,
                os quais s&atilde;o pessoais e intransfer&iacute;veis. A
                responsabilidade pela guarda e prote&ccedil;&atilde;o destas
                informa&ccedil;&otilde;es &eacute; de sua inteira e exclusiva
                responsabilidade.
              </p>

              <p>&nbsp;</p>

              <p>
                3.2 Cadastros desatualizados impedir&atilde;o o contato do
                LICENCIANTE com LICENCIADO. O LICENCIANTE n&atilde;o se
                responsabilizar&aacute; por qualquer fato decorrente da sua
                inabilidade para receber ou responder notifica&ccedil;&otilde;es
                enviadas pelo LICENCIANTE aos dados constantes do Cadastro.
              </p>

              <p>&nbsp;</p>

              <p>
                3.3 Menores de 18 (dezoito) anos ou incapazes, nos termos da
                Lei, n&atilde;o poder&atilde;o utilizar os servi&ccedil;os. Os
                respons&aacute;veis legais dos menores de 18 anos ou incapazes
                respondem pelos atos dos seus representados. O LICENCIANTE
                N&Atilde;O realizar&aacute; qualquer verifica&ccedil;&atilde;o
                nas informa&ccedil;&otilde;es fornecidas pelos Usu&aacute;rios,
                inclusive com rela&ccedil;&atilde;o &agrave; sua capacidade
                civil ou maioridade.
              </p>

              <p>&nbsp;</p>

              <p>
                3.4 Pessoas jur&iacute;dicas poder&atilde;o usar nossos
                servi&ccedil;os e ferramentas desde que o Cadastro e uso das
                Ferramentas sejam feitos por um representante legal da pessoa
                jur&iacute;dica. O LICENCIANTE poder&aacute; a qualquer momento
                solicitar a confirma&ccedil;&atilde;o de que o representante
                legal que efetuou o Cadastro tem poderes suficientes, cancelado
                o respectivo Cadastro e o uso das Ferramentas se n&atilde;o
                houver tal comprova&ccedil;&atilde;o.
              </p>

              <p>&nbsp;</p>

              <p>
                3.4 O LICENCIANTE poder&aacute; efetuar o cancelamento total ou
                parcial dos servi&ccedil;os de a qualquer tempo, sem aviso
                pr&eacute;vio e sem que tenha qualquer dever de
                indeniza&ccedil;&atilde;o junto ao LICENCIADO ou terceiros,
                inclusive, dentre outros, em casos de uso descontinuado das
                Ferramentas por per&iacute;odos acima de 30 dias.
              </p>

              <p>&nbsp;</p>

              <p>4.0 Do Prazo</p>

              <p>
                O presente EULA entra em vigor na data de seu aceite pelo
                LICENCIADO e vigorar&aacute; pelo prazo do Plano contratado pelo
                LICENCIADO. Este EULA ser&aacute; automaticamente renovado por
                iguais per&iacute;odos caso o LICENCIADO n&atilde;o se manifeste
                expressamente em contr&aacute;rio. Este EULA poder&aacute; ser
                rescindido conforme estabelecido abaixo nesse instrumento.
              </p>

              <p>&nbsp;</p>

              <p>5.0 Das obriga&ccedil;&otilde;es do LICENCIADO</p>

              <p>Obriga-se o LICENCIADO a:</p>

              <p>&nbsp;</p>

              <p>
                5.1 Manter pessoal treinado para a opera&ccedil;&atilde;o do
                SOFTWARE e para a comunica&ccedil;&atilde;o com a LICENCIANTE e
                prover, sempre que ocorrerem quaisquer problemas com o SOFTWARE,
                toda a documenta&ccedil;&atilde;o, relat&oacute;rios e demais
                informa&ccedil;&otilde;es que relatem as circunst&acirc;ncias em
                que os problemas ocorreram, objetivando facilitar e agilizar os
                trabalhos;
              </p>

              <p>&nbsp;</p>

              <p>
                5.2 Manter, &agrave;s suas expensas, linha de
                telecomunica&ccedil;&atilde;o, modem, software de
                comunica&ccedil;&atilde;o, endere&ccedil;o de correio
                eletr&ocirc;nico e outros recursos necess&aacute;rios &agrave;
                comunica&ccedil;&atilde;o com a LICENCIANTE;
              </p>

              <p>&nbsp;</p>

              <p>
                5.3 Responder pelas INFORMA&Ccedil;&Otilde;ES inseridas no
                SOFTWARE, pelo cadastramento, permiss&otilde;es, senhas e modo
                de utiliza&ccedil;&atilde;o de seus usu&aacute;rios. A
                LICENCIANTE em hip&oacute;tese alguma ser&aacute;
                respons&aacute;vel pelo conte&uacute;do
                (INFORMA&Ccedil;&Otilde;ES, senhas, c&oacute;pias de
                informa&ccedil;&otilde;es, etc.) inclu&iacute;do no SOFTWARE,
                n&atilde;o sendo, portanto, estas INFORMA&Ccedil;&Otilde;ES
                revisadas em momento algum. A responsabilidade pelas
                INFORMA&Ccedil;&Otilde;ES inseridas no SOFTWARE &eacute; sempre
                do LICENCIADO;
              </p>

              <p>&nbsp;</p>

              <p>
                5.4 Certificar-se de que n&atilde;o est&aacute; proibido por
                determina&ccedil;&atilde;o legal e/ou contratual de passar
                INFORMA&Ccedil;&Otilde;ES, INFORMA&Ccedil;&Otilde;ES
                FINANCEIRAS, INFORMA&Ccedil;&Otilde;ES DE CONTA e
                INFORMA&Ccedil;&Otilde;ES PESSOAIS, bem como quaisquer outros
                dados &agrave; LICENCIANTE, necess&aacute;rios para a
                execu&ccedil;&atilde;o do servi&ccedil;o oferecido por este
                EULA;
              </p>

              <p>&nbsp;</p>

              <p>
                5.5 N&atilde;o utilizar o SOFTWARE de qualquer forma que possa
                implicar em ato il&iacute;cito, infra&ccedil;&atilde;o,
                viola&ccedil;&atilde;o de direitos ou danos &agrave; LICENCIANTE
                ou terceiros, incluindo, mas n&atilde;o se limitando ao uso para
                invas&atilde;o de dispositivo inform&aacute;tico com o objetivo
                de obter, adulterar ou destruir dados ou
                informa&ccedil;&otilde;es sem a autoriza&ccedil;&atilde;o
                expressa do titular de tais dados ou do dispositivo ou servidor
                nos quais estes estejam armazenados;
              </p>

              <p>&nbsp;</p>

              <p>
                5.6 N&atilde;o publicar, enviar ou transmitir qualquer arquivo
                que contenha v&iacute;rus, worms, cavalos de troia ou qualquer
                outro programa que possa contaminar, destruir ou interferir no
                bom funcionamento do SOFTWARE;
              </p>

              <p>&nbsp;</p>

              <p>
                5.7 Informar a LICENCIANTE sempre que houver qualquer
                altera&ccedil;&atilde;o das INFORMA&Ccedil;&Otilde;ES fornecidas
                &agrave; LICENCIANTE e que possam impedir, limitar ou prejudicar
                o acesso da LICENCIANTE &agrave;s INFORMA&Ccedil;&Otilde;ES
                necess&aacute;rias para a execu&ccedil;&atilde;o das
                funcionalidades ofertadas pelo SOFTWARE;
              </p>

              <p>&nbsp;</p>

              <p>
                5.8 Caso o LICENCIADO acredite que seu login e senha de acesso
                ao SOFTWARE tenham sido roubados ou sejam de conhecimento de
                outras pessoas, por qualquer raz&atilde;o, o LICENCIADO
                dever&aacute; imediatamente comunicar tal fato &agrave;
                LICENCIANTE, sem preju&iacute;zo da altera&ccedil;&atilde;o da
                sua senha imediatamente, por meio do SOFTWARE.
              </p>

              <p>&nbsp;</p>

              <p>
                5.9 Quando o LICENCIADO se tratar de empresa de contabilidade,
                dever&aacute; indicar no ato do cadastramento os usu&aacute;rios
                autorizados a acessar a base cadastrada, assim como indicar o
                respons&aacute;vel financeiro pelo pagamento.
              </p>

              <p>&nbsp;</p>

              <p>6.0 Das obriga&ccedil;&otilde;es do LICENCIANTE</p>

              <p>Obriga-se a LICENCIANTE a:</p>

              <p>&nbsp;</p>

              <p>
                6.1 A LICENCIANTE garante ao LICENCIADO que o SOFTWARE
                dever&aacute; funcionar regularmente, se respeitadas as
                condi&ccedil;&otilde;es de uso definidas na
                documenta&ccedil;&atilde;o. Na ocorr&ecirc;ncia de falhas de
                programa&ccedil;&atilde;o (&ldquo;bugs&rdquo;), a LICENCIANTE
                obrigar-se-&aacute; a corrigir tais falhas, podendo a seu
                crit&eacute;rio substituir a c&oacute;pia dos Programas com
                falhas por c&oacute;pias corrigidas;
              </p>

              <p>&nbsp;</p>

              <p>
                6.2 Fornecer, ato cont&iacute;nuo ao aceite deste EULA, acesso
                ao SOFTWARE durante a vig&ecirc;ncia deste EULA;
              </p>

              <p>&nbsp;</p>

              <p>
                6.3 Suspender o acesso ao SOFTWARE do LICENCIADO que esteja
                desrespeitando as regras de conte&uacute;do aqui estabelecidas
                ou as normas legais em vigor;
              </p>

              <p>&nbsp;</p>

              <p>
                6.4 Alterar as especifica&ccedil;&otilde;es e/ou
                caracter&iacute;sticas do SOFTWARE licenciados para a melhoria
                e/ou corre&ccedil;&otilde;es de erros;
              </p>

              <p>&nbsp;</p>

              <p>
                6.5 Disponibilizar acesso aos servi&ccedil;os de suporte
                compreendido de segunda a sexta-feira, das 09:00h &agrave;s
                18:00h (pelo hor&aacute;rio de Bras&iacute;lia), sem intervalo,
                via chat (localizado no interior do SOFTWARE), por meio de
                correio eletr&ocirc;nico (atendimento@Agendou.com.br), por
                telefone e ainda atrav&eacute;s da p&aacute;gina de suporte
                (https://www.Agendou.com.br, dispon&iacute;vel 24 horas por dia
                e todos os dias da semana) para esclarecimento de d&uacute;vidas
                de ordem n&atilde;o t&eacute;cnica diretamente relacionadas ao
                SOFTWARE.
              </p>

              <p>&nbsp;</p>

              <p>
                6.6 Manter as INFORMA&Ccedil;&Otilde;ES FINANCEIRAS,
                INFORMA&Ccedil;&Otilde;ES DE CONTA e INFORMA&Ccedil;&Otilde;ES
                PESSOAIS do LICENCIADO, bem como seus registros de acesso, em
                sigilo, sendo que as referidas INFORMA&Ccedil;&Otilde;ES
                ser&atilde;o armazenadas em ambiente seguro, sendo respeitadas a
                intimidade, a vida privada, a honra e a imagem do LICENCIADO, em
                conformidade com as disposi&ccedil;&otilde;es da Lei n&ordm;
                12.965/2014.
              </p>

              <p>&nbsp;</p>

              <p>
                6.7 O LICENCIANTE durante tempo indeterminado oferece em
                car&aacute;ter de cortesia o envio de lembretes de agendamento
                por SMS, o mesmo pode ser descontinuado sem preju&iacute;zo de
                contrato.
              </p>

              <p>&nbsp;</p>

              <p>
                6.8 O LICENCIANTE oferece garantia de 85% de taxa de entrega das
                mensagens de SMS, podendo o cliente optar pelo formato SHORTCODE
                com garantia de entrega de 99% e tempo de entrega de at&eacute;
                5 minutos com custo adicional de R$ 0,07 por mensagem enviada.
              </p>

              <p>&nbsp;</p>

              <p>7.0 Da Rescis&atilde;o</p>

              <p>
                7.1 O LICENCIADO poder&aacute; rescindir este EULA a qualquer
                tempo, desde que comunique &agrave; LICENCIANTE, por escrito
                para o e-mail cancelamento@Agendou.com.br ou carta registrada,
                devendo pagar o saldo devedor do plano de licenciamento
                contratado, se existente. Para os planos de licenciamento com
                pagamento antecipado, caso o LICENCIADO decida rescindir este
                EULA antes do t&eacute;rmino do prazo contratado, o LICENCIANTE
                n&atilde;o restituir&aacute; ao LICENCIADO o saldo restante do
                plano de licenciamento contratado. Este valor ser&aacute; retido
                pela LICENCIANTE para cobrir os custos operacionais.
              </p>

              <p>&nbsp;</p>

              <p>
                7.2 A LICENCIANTE poder&aacute; rescindir este EULA a qualquer
                tempo, desde que comunique ao LICENCIADO, por escrito, com
                anteced&ecirc;ncia m&iacute;nima de 30 (trinta) dias, devendo
                neste caso restituir ao LICENCIADO o saldo devedor do plano de
                licenciamento contratado, se existente. .
              </p>

              <p>&nbsp;</p>

              <p>
                7.3 Para os planos de licenciamento com pagamento antecipado,
                caso a LICENCIANTE decida rescindir este EULA antes do
                t&eacute;rmino do prazo contratado, a LICENCIANTE
                restituir&aacute; ao LICENCIADO o saldo restante do plano de
                licenciamento contratado.
              </p>

              <p>&nbsp;</p>

              <p>
                7.4 A LICENCIANTE poder&aacute; rescindir o EULA a qualquer
                momento em caso de viola&ccedil;&atilde;o pelo LICENCIADO dos
                termos e condi&ccedil;&otilde;es ora acordados, ou em caso de
                atraso de pagamento n&atilde;o sanado no prazo de 60 (sessenta)
                dias.
              </p>

              <p>&nbsp;</p>

              <p>
                7.5 No caso de rescis&atilde;o do presente contrato, os dados
                pessoais, banc&aacute;rios, financeiros e demais
                informa&ccedil;&otilde;es do LICENCIADO ficar&atilde;o
                dispon&iacute;veis, sendo exclu&iacute;dos permanentemente
                ap&oacute;s 30 (trinta) dias da data da rescis&atilde;o.
              </p>

              <p>&nbsp;</p>

              <p>8.0 Da natureza do EULA</p>

              <p>&nbsp;</p>

              <p>
                8.1 Visto a presta&ccedil;&atilde;o de servi&ccedil;o declarado
                neste contrato ser a loca&ccedil;&atilde;o de software para
                gest&atilde;o empresarial e relacionamento com cliente, o
                LICENCIADO reconhece n&atilde;o ser o destinat&aacute;rio final
                dos servi&ccedil;os prestados pelo EULA;
              </p>

              <p>&nbsp;</p>

              <p>
                8.2 O LICENCIADO se declara ciente de que por causa da natureza
                do contrato o mesmo n&atilde;o poder&aacute; ser interpretado
                pelo CDC (C&oacute;digo de Defesa do Consumidor) onde em seu
                artigo 2&ordm; normaliza as condi&ccedil;&otilde;es para
                caracteriza&ccedil;&atilde;o da rela&ccedil;&atilde;o de
                consumo;
              </p>

              <p>&nbsp;</p>

              <p>9.0 Das disposi&ccedil;&otilde;es legais.</p>

              <p>&nbsp;</p>

              <p>
                9.2 Caso o LICENCIADO venha a desenvolver um novo m&oacute;dulo
                ou produto que caracterize c&oacute;pia, de todo ou em parte,
                quer seja do dicion&aacute;rio de dados quer seja do programa,
                ser&aacute; considerado como parte do SOFTWARE fornecido pela
                LICENCIANTE, ficando, portanto, sua propriedade incorporada pela
                LICENCIANTE e seu uso condicionado a estas cl&aacute;usulas
                contratuais;
              </p>

              <p>&nbsp;</p>

              <p>
                9.3 Este EULA obriga as partes e seus sucessores e somente o
                LICENCIADO possui licen&ccedil;a n&atilde;o exclusiva para a
                utiliza&ccedil;&atilde;o do SOFTWARE, sendo-lhe, entretanto,
                vedado transferir os direitos e obriga&ccedil;&otilde;es
                acordados por este instrumento. Tal limita&ccedil;&atilde;o, no
                entanto, n&atilde;o atinge a LICENCIANTE, que poder&aacute;, a
                qualquer tempo, ceder, no todo ou em parte, os direitos e
                obriga&ccedil;&otilde;es inerentes ao presente EULA;
              </p>

              <p>&nbsp;</p>

              <p>
                9.4 A toler&acirc;ncia de uma parte para com a outra quanto ao
                descumprimento de qualquer uma das obriga&ccedil;&otilde;es
                assumidas neste instrumento n&atilde;o implicar&aacute; em
                nova&ccedil;&atilde;o ou ren&uacute;ncia de direito. A parte
                tolerante poder&aacute;, a qualquer tempo, exigir da outra parte
                o fiel e cabal cumprimento deste instrumento;
              </p>

              <p>&nbsp;</p>

              <p>
                9.5 N&atilde;o constitui causa de rescis&atilde;o contratual o
                n&atilde;o cumprimento das obriga&ccedil;&otilde;es aqui
                assumidas em decorr&ecirc;ncia de fatos que independam da
                vontade das partes, tais como os que configuram o caso fortuito
                ou for&ccedil;a maior, conforme previsto no artigo 393 do
                C&oacute;digo Civil Brasileiro;
              </p>

              <p>&nbsp;</p>

              <p>
                9.6 Se qualquer disposi&ccedil;&atilde;o deste EULA for
                considerada nula, anul&aacute;vel, inv&aacute;lida ou
                inoperante, nenhuma outra disposi&ccedil;&atilde;o deste EULA
                ser&aacute; afetada como consequ&ecirc;ncia disso e, portanto,
                as disposi&ccedil;&otilde;es restantes deste EULA
                permanecer&atilde;o em pleno vigor e efeito como se tal
                disposi&ccedil;&atilde;o nula, anul&aacute;vel, inv&aacute;lida
                ou inoperante n&atilde;o estivesse contida neste EULA;
              </p>

              <p>&nbsp;</p>

              <p>
                9.7 O LICENCIADO concorda que a LICENCIANTE possa divulgar a
                celebra&ccedil;&atilde;o deste instrumento para fins comerciais,
                fazendo men&ccedil;&atilde;o ao nome e &agrave; marca do
                LICENCIADO em campanhas comerciais, podendo, inclusive, divulgar
                mensagens enviadas de forma escrita ou oral, por telefone, para
                uso em sites, jornais, revistas e outras campanhas, enquanto
                vigorar o presente EULA. O LICENCIADO aceita, ainda, receber
                comunica&ccedil;&otilde;es via correio eletr&ocirc;nico sobre
                treinamentos, parcerias e campanhas relacionadas ao SOFTWARE;
              </p>

              <p>&nbsp;</p>

              <p>
                9.8 Neste ato, o LICENCIANTE expressamente autoriza o LICENCIADO
                a colher e utilizar seus dados t&eacute;cnicos e operacionais
                presentes no SOFTWARE, para fins de estudos e melhorias no
                SOFTWARE;
              </p>

              <p>&nbsp;</p>

              <p>
                9.9 A LICENCIANTE poder&aacute;, ao seu exclusivo
                crit&eacute;rio, a qualquer tempo e sem a necessidade de
                comunica&ccedil;&atilde;o pr&eacute;via ao LICENCIADO:
              </p>

              <p>&nbsp;</p>

              <p>
                a. Encerrar, modificar ou suspender, total ou parcialmente, o
                acesso do LICENCIADO ao SOFTWARE, quando referido acesso ou
                cadastro estiver em viola&ccedil;&atilde;o das
                condi&ccedil;&otilde;es estabelecidas neste EULA;
              </p>

              <p>
                b. Excluir, total ou parcialmente, as informa&ccedil;&otilde;es
                cadastradas pelo LICENCIADO que n&atilde;o estejam em
                conson&acirc;ncia com as disposi&ccedil;&otilde;es deste EULA;
              </p>

              <p>
                c. Acrescentar, excluir ou modificar o Conte&uacute;do oferecido
                no site;
              </p>

              <p>
                d. Alterar quaisquer termos e condi&ccedil;&otilde;es deste EULA
                mediante simples comunica&ccedil;&atilde;o ao LICENCIADO.
              </p>

              <p>
                9.10 A LICENCIANTE ainda poder&aacute;, a seu exclusivo
                crit&eacute;rio, suspender, modificar ou encerrar as atividades
                do SOFTWARE, mediante comunica&ccedil;&atilde;o pr&eacute;via
                por escrito ao LICENCIADO, com anteced&ecirc;ncia m&iacute;nima
                de 15 (quinze) dias, disponibilizando formas e alternativas de
                extrair do Site as informa&ccedil;&otilde;es, salvo nas
                hip&oacute;teses de caso fortuito ou for&ccedil;a maior.
              </p>

              <p>&nbsp;</p>

              <p>
                9.11 A LICENCIANTE poder&aacute;, por meio de
                comunica&ccedil;&atilde;o ao endere&ccedil;o eletr&ocirc;nico
                indicado pelo LICENCIADO em seu cadastro ou por meio de aviso no
                Site, definir pre&ccedil;os para a oferta de determinados
                conte&uacute;dos e/ou servi&ccedil;os, ainda que inicialmente
                tais servi&ccedil;os tenham sido ofertados de forma gratuita,
                sendo a utiliza&ccedil;&atilde;o destes, ap&oacute;s o referido
                aviso, considerada como concord&acirc;ncia do LICENCIADO com a
                cobran&ccedil;a de tais pre&ccedil;os.
              </p>

              <p>&nbsp;</p>

              <p>
                9.12 Fica certo e entendido pelo LICENCIADO que somente a pessoa
                cadastrada pelo pr&oacute;prio LICENCIADO como administradora de
                conta poder&aacute; solicitar que as informa&ccedil;&otilde;es
                do LICENCIADO inseridas no Software sejam apagadas. Ainda, o
                LICENCIADO declara sua ci&ecirc;ncia de que uma vez apagadas,
                estas n&atilde;o poder&atilde;o mais ser recuperadas, ficando a
                LICENCIANTE isenta de qualquer responsabilidade por quaisquer
                perdas ou danos decorrentes deste procedimento solicitado pelo
                LICENCIADO.
              </p>

              <p>&nbsp;</p>

              <p>10.0 Da lei aplic&aacute;vel</p>

              <p>
                Este EULA ser&aacute; regido, interpretado e se sujeitar&aacute;
                &agrave;s leis brasileiras e, o LICENCIADO e a LICENCIANTE desde
                logo elegem, de forma irrevog&aacute;vel e irretrat&aacute;vel,
                o foro da Comarca da Cidade de S&atilde;o Paulo, Estado de
                S&atilde;o Paulo, para dirimir quaisquer d&uacute;vidas ou
                controv&eacute;rsias oriundas deste EULA, com a exclus&atilde;o
                de qualquer outro, por mais privilegiado que seja.
              </p>

              <p>&nbsp;</p>

              <p>11.0 Das disposi&ccedil;&otilde;es</p>

              <p>
                Os termos utilizados neste instrumento dever&atilde;o ser
                interpretados e usados conforme defini&ccedil;&otilde;es abaixo,
                seja no singular ou plural:
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES DE CONTA</strong>:
                informa&ccedil;&otilde;es e dados relativos a contas correntes,
                contas poupan&ccedil;as, cart&otilde;es de cr&eacute;dito,
                incluindo logins, senhas e demais informa&ccedil;&otilde;es
                necess&aacute;rias para acessar, coletar, armazenar, usar e
                tratar as informa&ccedil;&otilde;es obtidas nos sites das
                institui&ccedil;&otilde;es financeiras.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES FINANCEIRAS</strong>:
                informa&ccedil;&otilde;es fornecidas pelo LICENCIADO ou
                coletadas diretamente dos sites e dispositivos da
                institui&ccedil;&atilde;o financeira por meio do SOFTWARE com os
                dados do LICENCIADO, tais como recebimentos, pagamentos,
                investimentos, etc.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES PESSOAIS</strong>: qualquer
                informa&ccedil;&atilde;o disponibilizada pelo LICENCIADO que o
                identifique, tais como nome, endere&ccedil;o, data de
                nascimento, n&uacute;mero de telefone, fax, endere&ccedil;o
                eletr&ocirc;nico, n&uacute;mero de documentos, etc.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES</strong>: entende-se todas as
                informa&ccedil;&otilde;es do LICENCIADO relacionadas a
                INFORMA&Ccedil;&Otilde;ES PESSOAIS, INFORMA&Ccedil;&Otilde;ES
                FINANCEIRAS e INFORMA&Ccedil;&Otilde;ES DE CONTA.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>LICENCIADO</strong>: pessoa f&iacute;sica ou
                jur&iacute;dica, com plena capacidade de contratar, que acessa o
                SOFTWARE da LICENCIANTE por meio do site, realizando seu
                cadastro, aceitando os termos do presente EULA e usufruindo das
                funcionalidades oferecidos.
              </p>

              <p>&nbsp;</p>

              <p>
                <strong>SOFTWARE</strong>: software de propriedade exclusiva da
                LICENCIANTE, cujas funcionalidades e servi&ccedil;os
                est&atilde;o disponibilizados pelo site, por meio do qual as
                INFORMA&Ccedil;&Otilde;ES FINANCEIRAS do LICENCIADO ser&atilde;o
                fornecidas diretamente por ele ou coletadas diretamente nos
                sites das institui&ccedil;&otilde;es financeiras de maneira
                automatizada.
              </p>
            </IonContent>
          </IonModal>
        ) : null}

        {modalPoliticas ? (
          <IonModal
            className="modalPoliticas"
            isOpen={true}
            mode="ios"
            backdropDismiss="false"
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onclick={() => setModalPoliticas(false)}>
                    Fechar
                  </IonButton>
                </IonButtons>
                <IonTitle>Políticas de privacidade</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <p>
                Na Agendou , privacidade e seguran&ccedil;a s&atilde;o
                prioridades e nos comprometemos com a transpar&ecirc;ncia do
                tratamento de dados pessoais dos nossos
                usu&aacute;rios/clientes. Por isso, esta presente
                Pol&iacute;tica de Privacidade estabelece como &eacute; feita a
                coleta, uso e transfer&ecirc;ncia de informa&ccedil;&otilde;es
                de clientes ou outras pessoas que acessam ou usam nosso site. Ao
                utilizar nossos servi&ccedil;os, voc&ecirc; entende que
                coletaremos e usaremos suas informa&ccedil;&otilde;es pessoais
                nas formas descritas nesta Pol&iacute;tica, sob as normas de
                Prote&ccedil;&atilde;o de Dados (LGPD, Lei Federal 13.709/2018),
                das disposi&ccedil;&otilde;es consumeristas da Lei Federal
                8078/1990 e as demais normas do ordenamento jur&iacute;dico
                brasileiro aplic&aacute;veis.
              </p>

              <p>&nbsp;</p>

              <p>
                1. Quais dados coletamos sobre voc&ecirc; e para qual
                finalidade?
              </p>

              <p>
                Nosso site coleta e utiliza alguns dados pessoais seus, de forma
                a viabilizar a presta&ccedil;&atilde;o de servi&ccedil;os e
                aprimorar a experi&ecirc;ncia de uso.
              </p>

              <p>&nbsp;</p>

              <p>1.1. Dados pessoais fornecidos pelo titular</p>

              <p>- Nome completo</p>

              <p>
                - N&uacute;meros de telefone, WhatsApp e endere&ccedil;os de
                e-mail
              </p>

              <p>
                - Nome de usu&aacute;rio e senha espec&iacute;ficos para uso dos
                servi&ccedil;os da Controladora
              </p>

              <p>
                - Comunica&ccedil;&atilde;o, verbal e escrita, mantida entre o
                Titular e a Controladora
              </p>

              <p>
                - Base de contatos no celular, caso expressamente deseje a
                importa&ccedil;&atilde;o dessas informa&ccedil;&otilde;es em
                nosso sistema
              </p>

              <p>
                - Demais dados pertinentes ao devido uso dos servi&ccedil;os
              </p>

              <p>&nbsp;</p>

              <p>1.2. Dados pessoais coletados automaticamente</p>

              <p>
                Dados de navega&ccedil;&atilde;o nas p&aacute;ginas do sistema
              </p>

              <p>Estat&iacute;sticas de uso</p>

              <p>&nbsp;</p>

              <p>2. Como coletamos os seus dados?</p>

              <p>
                Nesse sentido, a coleta dos seus dados pessoais ocorre da
                seguinte forma:CookiesFormul&aacute;rios de cadastros de
                informa&ccedil;&otilde;es
              </p>

              <p>&nbsp;</p>

              <p>2.1. Consentimento</p>

              <p>
                &Eacute; a partir do seu consentimento que tratamos os seus
                dados pessoais. O consentimento &eacute; a
                manifesta&ccedil;&atilde;o livre, informada e inequ&iacute;voca
                pela qual voc&ecirc; autoriza a Agendou a tratar seus dados.
                Assim, em conson&acirc;ncia com a Lei Geral de
                Prote&ccedil;&atilde;o de Dados, seus dados s&oacute;
                ser&atilde;o coletados, tratados e armazenados mediante
                pr&eacute;vio e expresso consentimento.
              </p>

              <p>&nbsp;</p>

              <p>
                O seu consentimento ser&aacute; obtido de forma
                espec&iacute;fica para cada finalidade acima descrita,
                evidenciando o compromisso de transpar&ecirc;ncia e
                boa-f&eacute; da Agendou para com seus usu&aacute;rios/clientes,
                seguindo as regula&ccedil;&otilde;es legislativas pertinentes.
              </p>

              <p>&nbsp;</p>

              <p>
                Ao utilizar os servi&ccedil;os da Agendou e fornecer seus dados
                pessoais, voc&ecirc; est&aacute; ciente e consentindo com as
                disposi&ccedil;&otilde;es desta Pol&iacute;tica de Privacidade,
                al&eacute;m de conhecer seus direitos e como exerc&ecirc;-los.
              </p>

              <p>&nbsp;</p>

              <p>
                A qualquer tempo e sem nenhum custo, voc&ecirc; poder&aacute;
                revogar seu consentimento. &Eacute; importante destacar que a
                revoga&ccedil;&atilde;o do consentimento para o tratamento dos
                dados pode implicar a impossibilidade da performance adequada de
                alguma funcionalidade do site que dependa da
                opera&ccedil;&atilde;o. Tais consequ&ecirc;ncias ser&atilde;o
                informadas previamente.
              </p>

              <p>&nbsp;</p>

              <p>3. Quais s&atilde;o os seus direitos?</p>

              <p>
                A Agendou assegura a seus usu&aacute;rios/clientes seus direitos
                de titular previstos no artigo 18 da Lei Geral de
                Prote&ccedil;&atilde;o de Dados.
              </p>

              <p>&nbsp;</p>

              <p>
                Dessa forma, voc&ecirc; pode, de maneira gratuita e a qualquer
                tempo:
              </p>

              <p>
                - Confirmar a exist&ecirc;ncia de tratamento de dados, de
                maneira simplificada ou em formato claro e completo.
              </p>

              <p>
                - Acessar seus dados, podendo solicit&aacute;-los em uma
                c&oacute;pia leg&iacute;vel sob forma impressa ou por meio
                eletr&ocirc;nico, seguro e id&ocirc;neo.
              </p>

              <p>
                - Corrigir seus dados, ao solicitar a edi&ccedil;&atilde;o,
                corre&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o destes.
              </p>

              <p>
                - Limitar seus dados quando desnecess&aacute;rios, excessivos ou
                tratados em desconformidade com a legisla&ccedil;&atilde;o
                atrav&eacute;s da anonimiza&ccedil;&atilde;o, bloqueio ou
                elimina&ccedil;&atilde;o.
              </p>

              <p>
                - Solicitar a portabilidade de seus dados, atrav&eacute;s de um
                relat&oacute;rio de dados cadastrais que a Agendou trata a seu
                respeito.
              </p>

              <p>
                - Eliminar seus dados tratados a partir de seu consentimento,
                exceto nos casos previstos em lei.
              </p>

              <p>
                - Revogar seu consentimento, desautorizando o tratamento de seus
                dados.
              </p>

              <p>
                - Informar-se sobre a possibilidade de n&atilde;o fornecer seu
                consentimento e sobre as consequ&ecirc;ncias da negativa.
              </p>

              <p>&nbsp;</p>

              <p>4. Como voc&ecirc; pode exercer seus direitos de titular?</p>

              <p>
                Para exercer seus direitos de titular, voc&ecirc; deve entrar em
                contato com a Agendou atrav&eacute;s dos seguintes meios
                dispon&iacute;veis: lgpd@agendou.com.br
              </p>

              <p>&nbsp;</p>

              <p>
                De forma a garantir a sua correta identifica&ccedil;&atilde;o
                como titular dos dados pessoais objeto da
                solicita&ccedil;&atilde;o, &eacute; poss&iacute;vel que
                solicitemos documentos ou demais comprova&ccedil;&otilde;es que
                possam comprovar sua identidade. Nessa hip&oacute;tese,
                voc&ecirc; ser&aacute; informado previamente.
              </p>

              <p>&nbsp;</p>

              <p>
                5. Como e por quanto tempo seus dados ser&atilde;o armazenados?
              </p>

              <p>
                Seus dados pessoais coletados pela Agendou ser&atilde;o
                utilizados e armazenados durante o tempo necess&aacute;rio para
                a presta&ccedil;&atilde;o do servi&ccedil;o ou para que as
                finalidades elencadas na presente Pol&iacute;tica de Privacidade
                sejam atingidas, considerando os direitos dos titulares dos
                dados e dos controladores.
              </p>

              <p>&nbsp;</p>

              <p>
                De modo geral, seus dados ser&atilde;o mantidos enquanto a
                rela&ccedil;&atilde;o contratual entre voc&ecirc; e a Agendou
                perdurar.
              </p>

              <p>&nbsp;</p>

              <p>
                Findado o per&iacute;odo de armazenamento dos dados pessoais,
                estes ser&atilde;o exclu&iacute;dos de nossas bases de dados ou
                anonimizados, ressalvadas as hip&oacute;teses legalmente
                previstas no artigo 16 lei geral de prote&ccedil;&atilde;o de
                dados, a saber:
              </p>

              <p>&nbsp;</p>

              <p>
                I &ndash; cumprimento de obriga&ccedil;&atilde;o legal ou
                regulat&oacute;ria pelo controlador;
              </p>

              <p>
                II &ndash; estudo por &oacute;rg&atilde;o de pesquisa,
                garantida, sempre que poss&iacute;vel, a
                anonimiza&ccedil;&atilde;o dos dados pessoais;
              </p>

              <p>
                III &ndash; transfer&ecirc;ncia a terceiro, desde que
                respeitados os requisitos de tratamento de dados dispostos nesta
                Lei; ou
              </p>

              <p>
                IV &ndash; uso exclusivo do controlador, vedado seu acesso por
                terceiro, e desde que anonimizados os dados.
              </p>

              <p>&nbsp;</p>

              <p>
                Isto &eacute;, informa&ccedil;&otilde;es pessoais sobre
                voc&ecirc; que sejam imprescind&iacute;veis para o cumprimento
                de determina&ccedil;&otilde;es legais, judiciais e
                administrativas e/ou para o exerc&iacute;cio do direito de
                defesa em processos judiciais e administrativos ser&atilde;o
                mantidas, a despeito da exclus&atilde;o dos demais dados. O
                armazenamento de dados coletados pela Agendou reflete o nosso
                compromisso com a seguran&ccedil;a e privacidade dos seus dados.
                Empregamos medidas e solu&ccedil;&otilde;es t&eacute;cnicas de
                prote&ccedil;&atilde;o aptas a garantir a confidencialidade,
                integridade e inviolabilidade dos seus dados. Al&eacute;m disso,
                tamb&eacute;m contamos com medidas de seguran&ccedil;a
                apropriadas aos riscos e com controle de acesso &agrave;s
                informa&ccedil;&otilde;es armazenadas.
              </p>

              <p>&nbsp;</p>

              <p>6. O que fazemos para manter seus dados seguros?</p>

              <p>&nbsp;</p>

              <p>
                Para mantermos suas informa&ccedil;&otilde;es pessoais seguras,
                usamos ferramentas f&iacute;sicas, eletr&ocirc;nicas e
                gerenciais orientadas para a prote&ccedil;&atilde;o da sua
                privacidade. Aplicamos essas ferramentas levando em
                considera&ccedil;&atilde;o a natureza dos dados pessoais
                coletados, o contexto e a finalidade do tratamento e os riscos
                que eventuais viola&ccedil;&otilde;es gerariam para os direitos
                e liberdades do titular dos dados coletados e tratados.
              </p>

              <p>&nbsp;</p>

              <p>Entre as medidas que adotamos, destacamos as seguintes:</p>

              <p>
                - Apenas pessoas autorizadas t&ecirc;m acesso a seus dados
                pessoais
              </p>

              <p>
                - O acesso a seus dados pessoais &eacute; feito somente
                ap&oacute;s o compromisso de confidencialidade
              </p>

              <p>
                - Seus dados pessoais s&atilde;o armazenados em ambiente seguro
                e id&ocirc;neo.
              </p>

              <p>&nbsp;</p>

              <p>
                A Agendou se compromete a adotar as melhores posturas para
                evitar incidentes de seguran&ccedil;a. Contudo, &eacute;
                necess&aacute;rio destacar que nenhuma p&aacute;gina virtual
                &eacute; inteiramente segura e livre de riscos. &Eacute;
                poss&iacute;vel que, apesar de todos os nossos protocolos de
                seguran&ccedil;a, problemas de culpa exclusivamente de terceiros
                ocorram, como ataques cibern&eacute;ticos de hackers, ou
                tamb&eacute;m em decorr&ecirc;ncia da neglig&ecirc;ncia ou
                imprud&ecirc;ncia do pr&oacute;prio usu&aacute;rio/cliente.
              </p>

              <p>&nbsp;</p>

              <p>
                Em caso de incidentes de seguran&ccedil;a que possa gerar risco
                ou dano relevante para voc&ecirc; ou qualquer um de nossos
                usu&aacute;rios/clientes, comunicaremos aos afetados e a
                Autoridade Nacional de Prote&ccedil;&atilde;o de Dados sobre o
                ocorrido, em conson&acirc;ncia com as disposi&ccedil;&otilde;es
                da Lei Geral de Prote&ccedil;&atilde;o de Dados.
              </p>

              <p>&nbsp;</p>

              <p>7. Com quem seus dados podem ser compartilhados?</p>

              <p>&nbsp;</p>

              <p>
                Tendo em vista a preserva&ccedil;&atilde;o de sua privacidade, a
                Agendou n&atilde;o compartilhar&aacute; seus dados pessoais com
                nenhum terceiro n&atilde;o autorizado.
              </p>

              <p>&nbsp;</p>

              <p>
                Seus dados poder&atilde;o ser compartilhados com nossos
                parceiros comerciais: Estes recebem seus dados apenas na medida
                do necess&aacute;rio para a presta&ccedil;&atilde;o dos
                servi&ccedil;os contratados e nossos contratos s&atilde;o
                orientados pelas normas de prote&ccedil;&atilde;o de dados do
                ordenamento jur&iacute;dico brasileiro.
              </p>

              <p>&nbsp;</p>

              <p>
                Todavia, nossos parceiros t&ecirc;m suas pr&oacute;prias
                Pol&iacute;ticas de Privacidade, que podem divergir desta.
              </p>

              <p>&nbsp;</p>

              <p>
                Al&eacute;m disso, tamb&eacute;m existem outras hip&oacute;teses
                em que seus dados poder&atilde;o ser compartilhados, que
                s&atilde;o:
              </p>

              <p>&nbsp;</p>

              <p>
                I &ndash; Determina&ccedil;&atilde;o legal, requerimento,
                requisi&ccedil;&atilde;o ou ordem judicial, com autoridades
                judiciais, administrativas ou governamentais competentes.
              </p>

              <p>
                II &ndash; Caso de movimenta&ccedil;&otilde;es
                societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e
                incorpora&ccedil;&atilde;o, de forma autom&aacute;tica
              </p>

              <p>
                III &ndash; Prote&ccedil;&atilde;o dos direitos da Agendou em
                qualquer tipo de conflito, inclusive os de teor judicial.
              </p>

              <p>&nbsp;</p>

              <p>7.1. Transfer&ecirc;ncia internacional de dados</p>

              <p>&nbsp;</p>

              <p>
                Alguns dos terceiros com quem compartilhamos seus dados podem
                ser localizados ou ou possuir instala&ccedil;&otilde;es
                localizadas em pa&iacute;ses estrangeiros. Nessas
                condi&ccedil;&otilde;es, de toda forma, seus dados pessoais
                estar&atilde;o sujeitos &agrave; Lei Geral de
                Prote&ccedil;&atilde;o de Dados e &agrave;s demais
                legisla&ccedil;&otilde;es brasileiras de prote&ccedil;&atilde;o
                de dados
              </p>

              <p>&nbsp;</p>

              <p>
                Nesse sentido, a Agendou se compromete a sempre adotar
                eficientes padr&otilde;es de seguran&ccedil;a cibern&eacute;tica
                e de prote&ccedil;&atilde;o de dados, nos melhores
                esfor&ccedil;os de garantir e cumprir as exig&ecirc;ncias
                legislativas.Ao concordar com essa Pol&iacute;tica de
                Privacidade, voc&ecirc; concorda com esse compartilhamento, que
                se dar&aacute; conforme as finalidades descritas no presente
                instrumento.
              </p>

              <p>&nbsp;</p>

              <p>8. Cookies ou dados de navega&ccedil;&atilde;o</p>

              <p>&nbsp;</p>

              <p>
                A Agendou &nbsp;faz uso de Cookies, que s&atilde;o arquivos de
                texto enviados pela plataforma ao seu computador e que nele se
                armazenam, que cont&eacute;m informa&ccedil;&otilde;es
                relacionadas &agrave; navega&ccedil;&atilde;o do site. Em suma,
                os Cookies s&atilde;o utilizados para aprimorar a
                experi&ecirc;ncia de uso.
              </p>

              <p>&nbsp;</p>

              <p>
                Ao acessar nosso site e consentir com o uso de Cookies,
                voc&ecirc; manifesta conhecer e aceitar a
                utiliza&ccedil;&atilde;o de um sistema de coleta de dados de
                navega&ccedil;&atilde;o com o uso de Cookies em seu dispositivo.
              </p>

              <p>&nbsp;</p>

              <p>
                Voc&ecirc; pode, a qualquer tempo e sem nenhum custo, alterar as
                permiss&otilde;es, bloquear ou recusar os Cookies.
              </p>

              <p>&nbsp;</p>

              <p>
                Todavia, a revoga&ccedil;&atilde;o do consentimento de
                determinados Cookies pode inviabilizar o funcionamento correto
                de alguns recursos da plataforma.
              </p>

              <p>&nbsp;</p>

              <p>
                9. Altera&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade
              </p>

              <p>&nbsp;</p>

              <p>
                A atual vers&atilde;o da Pol&iacute;tica de Privacidade foi
                formulada e atualizada pela &uacute;ltima vez em: 04/01/2021.
              </p>

              <p>&nbsp;</p>

              <p>
                Reservamos o direito de modificar essa Pol&iacute;tica de
                Privacidade a qualquer tempo, principalmente em
                fun&ccedil;&atilde;o da adequa&ccedil;&atilde;o a eventuais
                altera&ccedil;&otilde;es feitas em nosso site ou em &acirc;mbito
                legislativo. Recomendamos que voc&ecirc; a revise com
                frequ&ecirc;ncia.
              </p>

              <p>&nbsp;</p>

              <p>
                Eventuais altera&ccedil;&otilde;es entrar&atilde;o em vigor a
                partir de sua publica&ccedil;&atilde;o em nosso site e sempre
                lhe notificaremos acerca das mudan&ccedil;as ocorridas.
              </p>

              <p>&nbsp;</p>

              <p>
                Ao utilizar nossos servi&ccedil;os e fornecer seus dados
                pessoais ap&oacute;s tais modifica&ccedil;&otilde;es, voc&ecirc;
                as consente.
              </p>

              <p>&nbsp;</p>

              <p>10. Responsabilidade</p>

              <p>&nbsp;</p>

              <p>
                A Agendou &nbsp;prev&ecirc; a responsabilidade dos agentes que
                atuam nos processos de tratamento de dados, em conformidade com
                os artigos 42 ao 45 da Lei Geral de Prote&ccedil;&atilde;o de
                Dados.Nos comprometemos em manter esta Pol&iacute;tica de
                Privacidade atualizada, observando suas
                disposi&ccedil;&otilde;es e zelando por seu cumprimento.
              </p>

              <p>&nbsp;</p>

              <p>
                Al&eacute;m disso, tamb&eacute;m assumimos o compromisso de
                buscar condi&ccedil;&otilde;es t&eacute;cnicas e organizativas
                seguramente aptas a proteger todo o processo de tratamento de
                dados.
              </p>

              <p>&nbsp;</p>

              <p>
                Caso a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados
                exija a ado&ccedil;&atilde;o de provid&ecirc;ncias em
                rela&ccedil;&atilde;o ao tratamento de dados realizado pela
                Agendou, comprometemo-nos a segui-las.
              </p>

              <p>&nbsp;</p>

              <p>10.1 Isen&ccedil;&atilde;o de responsabilidade</p>

              <p>&nbsp;</p>

              <p>
                Conforme mencionado no T&oacute;pico 6, embora adotemos elevados
                padr&otilde;es de seguran&ccedil;a a fim de evitar incidentes,
                n&atilde;o h&aacute; nenhuma p&aacute;gina virtual inteiramente
                livre de riscos. Nesse sentido, a Agendou n&atilde;o se
                responsabiliza por:
              </p>

              <p>&nbsp;</p>

              <p>
                I &ndash; Quaisquer consequ&ecirc;ncias decorrentes da
                neglig&ecirc;ncia, imprud&ecirc;ncia ou imper&iacute;cia dos
                usu&aacute;rios em rela&ccedil;&atilde;o a seus dados
                individuais. Garantimos e nos responsabilizamos apenas pela
                seguran&ccedil;a dos processos de tratamento de dados e do
                cumprimento das finalidades descritas no presente
                instrumento.Destacamos que a responsabilidade em
                rela&ccedil;&atilde;o &agrave; confidencialidade dos dados de
                acesso &eacute; do usu&aacute;rio.
              </p>

              <p>
                II &ndash; A&ccedil;&otilde;es maliciosas de terceiros, como
                ataques de hackers, exceto se comprovada conduta culposa ou
                deliberada da Agendou.
              </p>

              <p>&nbsp;</p>

              <p>
                Destacamos que em caso de incidentes de seguran&ccedil;a que
                possam gerar risco ou dano relevante para voc&ecirc; ou qualquer
                um de nossos usu&aacute;rios/clientes, comunicaremos aos
                afetados e a Autoridade Nacional de Prote&ccedil;&atilde;o de
                Dados sobre o ocorrido e cumpriremos as provid&ecirc;ncias
                necess&aacute;rias.
              </p>

              <p>&nbsp;</p>

              <p>
                III &ndash; Inveracidade das informa&ccedil;&otilde;es inseridas
                pelo usu&aacute;rio/cliente nos registros necess&aacute;rios
                para a utiliza&ccedil;&atilde;o dos servi&ccedil;os da Agendou;
                quaisquer consequ&ecirc;ncias decorrentes de
                informa&ccedil;&otilde;es falsas ou inseridas de
                m&aacute;-f&eacute; s&atilde;o de inteiramente responsabilidade
                do usu&aacute;rio/cliente.
              </p>

              <p>&nbsp;</p>

              <p>11. Encarregado de Prote&ccedil;&atilde;o de Dados</p>

              <p>
                A Agendou disponibiliza os seguintes meios para que voc&ecirc;
                possa entrar em contato conosco para exercer seus direitos de
                titular: E-mail e Correspond&ecirc;ncia.
              </p>

              <p>
                Caso tenha d&uacute;vidas sobre esta Pol&iacute;tica de
                Privacidade ou sobre os dados pessoais que tratamos, voc&ecirc;
                pode entrar em contato com o nosso Encarregado de
                Prote&ccedil;&atilde;o de Dados Pessoais, atrav&eacute;s dos
                seguintes canais: Davi Iglesias
                273.523.998-52lgpd@Agendou.com.br
              </p>
            </IonContent>
          </IonModal>
        ) : null}
      </IonContent>
    </IonPage>
  );
}
