import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { urlListar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import { IonPage, IonContent, IonButton } from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";

import DiaSemana from "./DiaSemana";

export default function HorarioAtendimentoEmpresas() {

  const [itens, setItens] = useState([]);
  const [atualiza, setAtualiza] = useState(0);
  const location = useLocation();

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = partesDoCaminho[2];

    const url = urlListar(tipo, tela);
    const lista = await consultaLista(url);
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, []);

  const atualizar = () => {
    setAtualiza(1);
  }

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="row">
          {itens.map((horario, index) => (
            <div className="col-sm-3 col-12" key={index}>
              <DiaSemana horario={horario} atualizar={atualiza} resetAtualizar={(value) => setAtualiza(value)} />
            </div>
          ))}
        </div>
        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => atualizar()}
          >
            Atualizar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
