import React from "react";
import { IonCard } from "@ionic/react";

export default function CardResumo({ texto, valor, icone }) {
  return (
    <div>
      <IonCard color="secondary-light">
        <div className="ion-margin">
          <div className="row">
            <div className="col-4 ion-text-end">
              <div
                style={{
                  background: "#fff",
                  color: "#13aad7",
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 40,
                  width: 40,
                  borderRadius: 8,
                  marginLeft: 10,
                }}
              >
                <i className={icone}></i>
              </div>
            </div>
            <div className="col-8"></div>
            <div className="col-12">
              <h1 style={{ margin: 0, marginTop: 10, fontSize: 30 }}>
                &nbsp;&nbsp;{valor}
              </h1>
            </div>
            <div className="col-12">
              <h6 style={{ margin: 0, marginTop: 10, fontSize: 11, }}>
                &nbsp;&nbsp;{texto}
              </h6>
            </div>
          </div>
        </div>
      </IonCard>
    </div>
  );
}
