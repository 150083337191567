import React, { useState } from "react";
import MenuSide from "../../../components/empresas/MenuSide";
import { IonPage, IonContent } from "@ionic/react";
import AgendamentosSemana from "./AgendamentosSemana";
import HeaderApp from "../../../components/global/HeaderItem";
import CardResumo from "./CardResumo";
export default function CalendarioEmpresas() {
  const [clientes, setClientes] = useState(0);
  const [agendamentos, setAgendamentos] = useState(0);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin">
          <div className="row">
            <div className="col-6 col-sm-4">
              <CardResumo
                texto={"Total de clientes"}
                valor={clientes}
                icone={"ri-user-line"}
              />
            </div>
            <div className="col-6 col-sm-4">
              <CardResumo
                texto={"Total de agendamentos"}
                valor={agendamentos}
                icone={"ri-calendar-line"}
              />
            </div>
            <div  className="col-12 col-sm-4">
            <AgendamentosSemana
              totalAgendamentos={(value) => setAgendamentos(value)}
              totalClientes={(value) => setClientes(value)}
            />
            </div>
          </div>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
