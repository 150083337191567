import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  urlAlterar,
  urlInformacoes,
  tokenUsuario,
  urlApagar,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function DiasNaoAtendidosEmpresas() {
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const [dia, setDia] = useState("");

  const consultar = async () => {
    try {
      const url = urlInformacoes("empresas", "dias_nao_atendidos", `id=${idPage}`);
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const atualizar = async () => {
    try {
      const response = await axios.post(urlAlterar("empresas", "dias_nao_atendidos"), {
        token: tokenUsuario,
        dia: dia,
        id: idPage,
      });
      if (response) {
        navigate({ pathname: "/empresas/dias_nao_atendidos" });
      }
    } catch (error) {
      return null;
    }
  };

  const apagar = async () => {
    try {
      const response = await axios.post(urlApagar("empresas", "dias_nao_atendidos"), {
        token: tokenUsuario,
        id: idPage,
      });
      if (response) {
        navigate({ pathname: "/empresas/dias_nao_atendidos" });
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      let consulta = await consultar();
      setDia(consulta[0].dia);
    };
    run();
  }, []);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin ion-text-end">
          <IonButton color="danger" onClick={() => apagar()}>
            <i className="ri-delete-bin-line"></i>
          </IonButton>
        </div>
        <IonItem>
          <IonLabel position="stacked">Dia: </IonLabel>
          <IonInput
            type="date"
            placeholder="Dia"
            onIonInput={(event) => {
              event.preventDefault();
              setDia(event.target.value);
            }}
            value={dia}
          />
        </IonItem>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => atualizar()}
          >
            Atualizar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
