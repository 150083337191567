import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

import { alertNotification } from "../../../utils/functions";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const [titulo, setTitulo] = useState("");
  const [dsc, setDsc] = useState("");

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "suporte"), {
        token: tokenUsuario,
        titulo: titulo,
        dsc: dsc,
      });
      if (response) {
        const funcao = () => {
          navigate({ pathname: "/empresas/suporte" });
        };
        let tituloD = "Atenção";
        let dscD =
          "Recebemos sua solicitação. Por favor, aguarde o contato da nossa equipe via Whatsapp/E-mail!";
        alertNotification(tituloD, dscD, "OK", funcao);
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Título da dúvida/Problema: </IonLabel>
          <IonInput
            type="text"
            placeholder="Título"
            onIonInput={(event) => {
              event.preventDefault();
              setTitulo(event.target.value);
            }}
            value={titulo}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Descrição da dúvida/Problema: </IonLabel>
          <IonTextarea
            className="obs"
            placeholder="Descrição da dúvida/Problema"
            onIonChange={(e) => setDsc(e.detail.value)}
            value={dsc}
            rows={15}
          ></IonTextarea>
        </IonItem>
        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
