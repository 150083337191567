import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  urlAlterar,
  urlInformacoes,
  tokenUsuario,
  urlApagar,
} from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function ServicosEmpresas() {
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [tempo, setTempo] = useState("");

  const consultar = async () => {
    try {
      const url = urlInformacoes("empresas", "servicos", `id=${idPage}`);
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const atualizar = async () => {
    try {
      const response = await axios.post(urlAlterar("empresas", "servicos"), {
        token: tokenUsuario,
        nome: nome,
        valor: valor,
        tempo: tempo,
        id: idPage,
      });
      if (response) {
        navigate({ pathname: "/empresas/servicos" });
      }
    } catch (error) {
      return null;
    }
  };

  const apagar = async () => {
    try {
      const response = await axios.post(urlApagar("empresas", "servicos"), {
        token: tokenUsuario,
        id: idPage,
      });
      if (response) {
        navigate({ pathname: "/empresas/servicos" });
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const run = async () => {
      let consulta = await consultar();
      setNome(consulta[0].nome);
      setValor(consulta[0].valor);
      setTempo(consulta[0].tempo);
    };
    run();
  }, []);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin ion-text-end">
          <IonButton color="danger" onClick={() => apagar()}>
            <i className="ri-delete-bin-line"></i>
          </IonButton>
        </div>
        <IonItem>
          <IonLabel position="stacked">Nome: </IonLabel>
          <IonInput
            type="text"
            placeholder="Nome"
            onIonInput={(event) => {
              event.preventDefault();
              setNome(event.target.value);
            }}
            value={nome}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Valor: </IonLabel>
          <IonInput
            type="tel"
            placeholder="Valor"
            onIonInput={(event) => {
              event.preventDefault();
              setValor(event.target.value);
            }}
            value={valor}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Tempo: </IonLabel>
          <IonInput
            type="tel"
            placeholder="Tempo"
            onIonInput={(event) => {
              event.preventDefault();
              setTempo(event.target.value);
            }}
            value={tempo}
          />
        </IonItem>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => atualizar()}
          >
            Atualizar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
