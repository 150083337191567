import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlAdicionar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
} from "@ionic/react";
import HeaderApp from "../../../components/global/HeaderItem";

export default function DiasNaoAtendidosEmpresas() {
  const navigate = useNavigate();

  const [dia, setDia] = useState("");

  const cadastrar = async () => {
    try {
      const response = await axios.post(urlAdicionar("empresas", "dias_nao_atendidos"), {
        token: tokenUsuario,
        dia: dia,
      });
      if (response) {
        navigate({ pathname: "/empresas/dias_nao_atendidos" });
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <IonItem>
          <IonLabel position="stacked">Dia: </IonLabel>
          <IonInput
            type="date"
            placeholder="Dia"
            onIonInput={(event) => {
              event.preventDefault();
              setDia(event.target.value);
            }}
            value={dia}
          />
        </IonItem>

        <div className="ion-margin">
          <IonButton
            color="secondary-light"
            expand="block"
            onClick={() => cadastrar()}
          >
            Confirmar
          </IonButton>
        </div>
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
