import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import axios from "axios";
import { urlListar, tokenUsuario } from "../../../utils/constants";
import MenuSide from "../../../components/empresas/MenuSide";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonPage,
  IonContent,
  IonInput,
  IonSpinner,
  IonRow,
  IonCol,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";

import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function AgendamentosUsuario() {
  const navigate = useNavigate();

  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [pagina, setPagina] = useState(1);
  const [loading, setLoading] = useState(true);

  const [itens, setItens] = useState([]);
  const location = useLocation();

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url + `?pagina=${pagina}`, {
        token: tokenUsuario,
        data_inicial: dataInicial,
        data_final: dataFinal,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = partesDoCaminho[2];

    const url = urlListar(tipo, tela);
    const lista = await consultaLista(url);
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, [pagina, dataFinal]);

  return (
    <IonPage>
      <HeaderApp />
      <IonContent>
        <div className="ion-margin-horizontal">
          <div className="row">
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataInicial(event.target.value);
                  }}
                  value={dataInicial}
                />
              </IonItem>
            </div>
            <div className="col-6">
              <IonItem>
                <IonLabel position="stacked">Data Inicial: </IonLabel>
                <IonInput
                  type="date"
                  onIonInput={(event) => {
                    event.preventDefault();
                    setDataFinal(event.target.value);
                  }}
                  value={dataFinal}
                />
              </IonItem>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="ion-text-center" style={{ marginTop: "10vh" }}>
            <IonSpinner name="crescent" />
          </div>
        ) : (
          <div>
            {Array.isArray(itens) && (
              <div>
                <div>
                  <div>
                    <IonRow>
                      {itens.map((row, index) => (
                        <IonCol key={index} sizeSm="4">
                          <IonItem
                            onClick={() => {
                              navigate({
                                pathname: "/empresas/agendamento",
                                search: createSearchParams({
                                  id: row.id,
                                }).toString(),
                              });
                            }}
                            
                          >
                            <IonLabel>
                              <h5
                                style={{
                                  textTransform: "capitalize",
                                  fontWeight: 700,
                                }}
                              >
                                {moment(row.data_agendamento).format("D")} de{" "}
                                {moment(row.data_agendamento).format("MMMM")} de{" "}
                                {moment(row.data_agendamento).format("YYYY")} :{" "}
                                {moment(
                                  "2023-01-01 " + row.horario_inicial
                                ).format("HH:mm")}{" "}
                                -{" "}
                                {moment(
                                  "2023-01-01 " + row.horario_final
                                ).format("HH:mm")}
                              </h5>
                              <h5>Serviço: {row.servico}</h5>
                              <h5>Cliente: {row.cliente_nome}</h5>
                              <StatusAgendamento status={row.status} />
                              <p
                                style={{
                                  marginTop: "7px",
                                  marginBottom: "5px",
                                }}
                              >
                                <i className="ri-information-line"></i> &nbsp;{" "}
                                {row.obs}
                              </p>
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      ))}
                    </IonRow>
                  </div>
                </div>
              </div>
            )}

            <div className="ion-margin-horizontal">
              <div className="row">
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => Math.max(prevPagina - 1, 1));
                      }}
                    >
                      Anterior
                    </IonButton>
                  </div>
                </div>
                <div className="col-6">
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <IonButton
                      expand="block"
                      color="secondary-light"
                      onClick={() => {
                        setPagina((prevPagina) => prevPagina + 1);
                      }}
                    >
                      Próximo
                    </IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
