import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  urlInformacoes,
  urlCancelarAgendamentoUsuario,
  tokenUsuario,
} from "../../../utils/constants";
import MenuSide from "../../../components/usuarios/MenuSide";
import {
  IonButton,
  IonLabel,
  IonPage,
  IonContent,
  IonCard,
} from "@ionic/react";

import HeaderApp from "../../../components/global/HeaderItem";
import StatusAgendamento from "../../../components/global/StatusAgendamento";

import { toastNotificacao, showActionSheet } from "../../../utils/functions";
import moment from "moment";
import "moment/locale/pt-br";
moment().locale("pt-br");

export default function AgendamentoUsuario() {
  const [itens, setItens] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idPage = urlParams.get("id");

  const consultaLista = async (url) => {
    try {
      const response = await axios.post(url, {
        token: tokenUsuario,
      });
      return response.data[0];
    } catch (error) {
      console.log(error);
    }
  };

  const consultarItens = async () => {
    const pathname = location.pathname;

    const partesDoCaminho = pathname.split("/");
    const tipo = partesDoCaminho[1];
    const tela = "agendamentos";

    const url = urlInformacoes(tipo, tela, `id=${idPage}`);
    const lista = await consultaLista(url);
    setItens(lista);
  };

  useEffect(() => {
    consultarItens();
  }, []);

  function calcularDiferencaEmMinutos(horario1, horario2) {
    if (horario1 && horario2) {
      // Divida os horários em horas, minutos e segundos
      const [hora1, minuto1, segundo1] = horario1.split(":").map(Number);
      const [hora2, minuto2, segundo2] = horario2.split(":").map(Number);

      // Calcule a diferença em minutos
      const minutos1 = hora1 * 60 + minuto1 + segundo1 / 60;
      const minutos2 = hora2 * 60 + minuto2 + segundo2 / 60;

      // Calcule a diferença entre os minutos
      const diferencaEmMinutos = Math.abs(minutos2 - minutos1);

      return diferencaEmMinutos;
    }
  }

  const solicitarCancelamento = () => {
    showActionSheet("Deseja realmente cancelar o agendamento?", [
      {
        text: "Sim",
        handler: () =>
          axios
            .post(urlCancelarAgendamentoUsuario, {
              token: tokenUsuario,
              id: idPage,
            })
            .then(async () => {
              const irParaAgendmamentos = () => {
                navigate({ pathname: "/usuarios/agendamentos" });
              };
              await toastNotificacao(
                "success",
                "alert-outline",
                "Solicitação enviada com sucesso, aguarde a confirmação do cancelamento pela empresa!",
                2000,
                "Ok",
                irParaAgendmamentos()
              );
              setTimeout(() => {
                navigate({ pathname: "/usuarios/agendamentos" });
              }, 3000);
            })
            .catch(async (erro) => {
              await toastNotificacao(
                "danger",
                "alert-outline",
                erro,
                60000,
                "Ok"
              );
            }),
      },
      {
        text: "Não",
        handler: () => {
          return;
        },
      },
    ]);
  };

  return (
    <IonPage>
      <HeaderApp />
      <IonContent className="contentAgendamento">
        <br />
        <br />
        <div className="ion-text-center">
          <h1 style={{ fontSize: 40 }}>
            {calcularDiferencaEmMinutos(
              itens.horario_inicial,
              itens.horario_final
            )}
            <b style={{ fontSize: 15 }}>&nbsp;min</b>
          </h1>
        </div>

        <IonCard>
          <IonLabel>
            <div className="ion-padding">
              <h2>
                <i className="ri-calendar-line"></i>&nbsp;
                {moment(itens.data_agendamento).format("D")} de{" "}
                {moment(itens.data_agendamento).format("MMMM")} de{" "}
                {moment(itens.data_agendamento).format("YYYY")}
              </h2>
              <h5 style={{ marginTop: "7px", marginBottom: "5px" }}>
                <i className="ri-time-line"></i>&nbsp;
                {moment("2023-01-01 " + itens.horario_inicial).format(
                  "HH:mm"
                )}{" "}
                / {moment("2023-01-01 " + itens.horario_final).format("HH:mm")}
              </h5>
              <h5>
                <i className="ri-store-2-line"></i>&nbsp; Serviço:{" "}
                {itens.servico}
              </h5>
              {itens.status && (
                <h5 style={{ marginTop: "7px", marginBottom: "5px" }}>
                <StatusAgendamento status={itens.status} />
                </h5>
              )}
            </div>
          </IonLabel>
        </IonCard>

        {itens.cancelavel == 1 ? (
          <div className="ion-margin-horizontal">
            <IonButton
              color="danger"
              expand="block"
              onClick={() => solicitarCancelamento()}
            >
              Cancelar
            </IonButton>
          </div>
        ) : (
          <span></span>
        )}
      </IonContent>
      <MenuSide />
    </IonPage>
  );
}
