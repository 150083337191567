/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  urlLoginUsuario,
  apiUrl,
  tokenUsuario,
} from "../../../utils/constants";
import axios from "axios";
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonCard,
  IonContent,
} from "@ionic/react";

import { showActionSheet, toastNotificacao, loadingRequest } from "../../../utils/functions";
export default function LoginUsuario() {
  const navigate = useNavigate();
  const [codigo, setcodigo] = useState(55);
  const [ddd, setDDD] = useState("");
  const [numero, setNumero] = useState("");
  const url = window.location.href;
  const urlBase = new URL(url);
  const empresaParms = urlBase.searchParams.get("empresa");

  const fazerLogin = async () => {
    const { dismiss } = loadingRequest("Aguarde...", 60000);
    try {
      const response = await axios.post(urlLoginUsuario, {
        codigo: codigo,
        ddd: ddd,
        telefone: numero,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status == "1") {
        dismiss();
        navigate({
          pathname: "/usuarios/codigo_verificacao",
        });
      } else if (response.data.status == "2") {
        dismiss();
        localStorage.setItem("codigo", codigo);
        localStorage.setItem("DDD", ddd);
        localStorage.setItem("numero", numero);
        if (empresaParms !== null) {
          navigate({
            pathname: "/usuarios/cadastrar",
            search: createSearchParams({
              empresa: empresaParms,
            }).toString(),
          });
        } else {
          dismiss();
          navigate({
            pathname: "/usuarios/cadastrar",
          });
        }
      } 
    } catch (error) {
      console.log(error);
      dismiss();
    }
  };

  const verificarCampos = async () => {
    if (ddd.length < 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (ddd.length > 2) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu DDD precisa ter 2 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length < 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    if (numero.length > 9) {
      return toastNotificacao(
        "danger",
        "alert-outline",
        "Seu número precisa ter 9 dígitos",
        10000,
        "Ok"
      );
    }

    showActionSheet(
      "O número +" + codigo + " " + ddd + numero + " está correto?",
      [
        {
          text: "Sim",
          handler: () => {
            fazerLogin();
          },
        },
        {
          text: "Fechar",
          handler: () => {
            return;
          },
        },
      ]
    );
  };

  const irParaCadastro = () => {
    if (empresaParms !== null) {
      navigate({
        pathname: "/usuarios/cadastrar",
        search: createSearchParams({
          empresa: empresaParms,
        }).toString(),
      });
    } else {
      navigate({
        pathname: "/usuarios/cadastrar",
      });
    }
  };

  useEffect(() => {
    const verificaSessao = async () => {
      try {
        const response = await axios.post(apiUrl + "usuarios/sessao/sessao", {
          token: tokenUsuario,
        });
        if (response.data === 1) {
          navigate({
            pathname: "/usuarios/inicio",
          });
        }
      } catch (error) {
        return null;
      }
    };
    verificaSessao();
  }, []);

  return (
    <IonContent>
      <div className="ion-margin">
        <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
            <IonCard>
              <div className="ion-margin centro" name="logarusuario">
                <div style={{ margin: "1%" }}>
                  <img
                    src="https://gerenciadores.com/apps/agendou/assets/img/logo.png"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "1rem",
                      margin: "2%",
                    }}
                  />
                </div>

                <h2 className="nomeDoApp"></h2>

                <IonText color="medium">
                  <p className="IonText-center">
                    Preencha os campos abaixo para continuar
                  </p>
                </IonText>

                <IonItem>
                  <IonLabel position="stacked">Código do país: </IonLabel>
                  <IonInput
                    type="tel"
                    className="codigo"
                    maxlength={"5"}
                    minlength={"2"}
                    placeholder="Código do país"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setcodigo(event.target.value);
                    }}
                    value={codigo}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">DDD: </IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={"2"}
                    minlength={"2"}
                    className="ddd"
                    placeholder="Digite seu DDD"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setDDD(event.target.value);
                    }}
                    value={ddd}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Número: </IonLabel>
                  <IonInput
                    type="tel"
                    maxlength={"9"}
                    minlength={"9"}
                    className="numero"
                    placeholder="Digite seu número"
                    onIonInput={(event) => {
                      event.preventDefault();
                      setNumero(event.target.value);
                    }}
                    value={numero}
                  />
                </IonItem>

                <IonButton
                  color="secondary-light"
                  onClick={verificarCampos}
                  expand="full"
                  className="botaoLogar"
                >
                  Entrar &nbsp; <i className="ri-shield-check-line"></i>
                </IonButton>

                <IonText>
                  <p>Ainda não tem conta?</p>
                </IonText>

                <IonButton
                  color="light"
                  onClick={irParaCadastro}
                  expand="full"
                  className=""
                >
                  Cadastre-se &nbsp; <i className="ri-shield-check-line"></i>
                </IonButton>
              </div>
            </IonCard>
          </div>

          <div className="col-sm-4"></div>
        </div>
      </div>
    </IonContent>
  );
}
